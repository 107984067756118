// src/pages/Signup.tsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  useTheme,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import PrimaryTextInput from '../../Components/PrimaryTextInput';
import ConfirmDialogBox from '../../Components/ConfirmDialogBox';
import ImgSignup from '../../Assets/ImgSignup.png';
import ImgLogo from '../../Assets/logo-net-dark.png';
import SnackAlert from '../../Components/SnackAlert';
import { awsOnUserSignup } from '../../Services/Aws/User';
import Button from '../../Components/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface ErrorProps {
  usernameError: string;
  emailError: string;
  passwordError: string;
  confirmPasswordError: string;
}

type Severity = 'error' | 'info' | 'warning' | 'success';

interface AlertProps {
  open: boolean;
  message: string;
  severity: Severity;
  duration: number | undefined;
}
// eslint-disable-next-line 
const emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const Signup: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [username, setUsername] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [error, setError] = React.useState<ErrorProps>({
    usernameError: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
  });
  const [showConfirmDialogBox, setShowConfirmDialogBox] =
    React.useState<boolean>(false);
  const [alertState, setAlertState] = React.useState<AlertProps>({
    open: false,
    message: '',
    severity: 'success',
    duration: 3000,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const onRedirectToLogin = (): void => {
    setShowConfirmDialogBox(false);
    navigate('/login');
  };

  const onValidate = (): boolean => {
    if (username.length === 0) {
      setError({ ...error, usernameError: 'Required!' });
      return false;
    } else if (email.length === 0) {
      setError({ ...error, emailError: 'Required!' });
      return false;
    } else if (!emailRegex.test(email)) {
      setError({ ...error, emailError: 'Invalid Email!' });
      return false;
    } else if (password.length === 0) {
      setError({ ...error, passwordError: 'Required!' });
      return false;
    } else if (passwordScore && passwordScore < 3 ) {
        setError({ ...error, passwordError: 'Please use stronger password!' });
        return false;
    } else if (confirmPassword.length === 0) {
      setError({ ...error, confirmPasswordError: 'Required!' });
      return false;
    } else if (password !== confirmPassword) {
      setError({ ...error, confirmPasswordError: 'Password does not match!' });
      return false;
    } else {
      return true;
    }
  };

  const onChangeUsername = (value: string): void => {
    setError({ ...error, usernameError: '' });
    setUsername(value);
  };

  const onChangeEmail = (value: string): void => {
    setError({ ...error, emailError: '' });
    setEmail(value);
  };

  const onChangePassword = (value: string): void => {
    setError({ ...error, passwordError: '' });
    setPassword(value);
  };

  const onChangeConfirmPassword = (value: string): void => {
    setError({ ...error, confirmPasswordError: '' });
    setConfirmPassword(value);
  };

  const onSignUp = (event: React.FormEvent): void => {
    event.preventDefault();

    if (onValidate()) {
      setLoading(true);

      awsOnUserSignup(email, password, username)
        .then(() => {
          setLoading(false);
          setShowConfirmDialogBox(true);
        })
        .catch((err: any) => {
          setLoading(false);
          handleSignupError(err);
        });
    }
  };

  const handleSignupError = (err: any): void => {
    if (err.name === 'InvalidPasswordException') {
      setError({
        ...error,
        passwordError:
          'Password should have 8 or more characters and should contain at least one special character, capital letter and number.',
      });
    } else if (err.name === 'UsernameExistsException') {
      setError({ ...error, emailError: err.message });
    } else {
      console.log('onSignUp Error:', err);
      setAlertState({
        ...alertState,
        open: true,
        message: 'Something went wrong!',
        duration: 3000,
        severity: 'error',
      });
    }
  };

  const onCloseAlert = (): void => {
    setAlertState({ ...alertState, open: false });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [passwordScore, setPasswordScore] = useState(null);
  const [passwordScoreFeedback, setPasswordScoreFeedback] = useState<any>(null);

  function checkPassword(zxcvbn: any, password: string, minLength = 8) {
    if (password.length < minLength) {
      return {
        score: 0,
        feedback: {
          warning: `Password must be at least ${minLength} characters long`,
          suggestions: ['Add more characters to meet the minimum length requirement']
        }
      };
    }
    
    return zxcvbn(password);
  }

  useEffect(() => {
    if (password) {

      import('zxcvbn').then((zxcvbn) => {
        const result: any = checkPassword(zxcvbn.default, password);
        console.log('result: ', result)
        setPasswordScore(result.score);
        setPasswordScoreFeedback(result.feedback);
      });

    } else {
      setPasswordScore(null);
    }
  }, [password]);

  // Function to map password score to a color
  const getColorForStrength = (score: any) => {
    if (score <= 1) return theme.palette.error.main;
    if (score === 2) return theme.palette.warning.main//'#ff9800'; // Orange
    // if (score === 2) return '#ff9800'; // Orange
    if (score === 3) return theme.palette.success.main//'#4caf50'; // Green
    // if (score === 3) return '#4caf50'; // Green
    return theme.palette.success.main; // Very Strong
  };

  const getSectionColors = () => {
    const colors = ['grey', 'grey', 'grey', 'grey', 'grey'];
    if (passwordScore !== null) {
      for (let i = 0; i <= passwordScore; i++) {
        colors[i] = getColorForStrength(passwordScore)//i);
      }
    }
    return colors;
  };

  const sectionColors = getSectionColors();

  console.log('sectionColors: ', sectionColors)

  return (
    <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: `linear-gradient(135deg, ${theme.palette.primary.backgroundLight} 30%, ${theme.palette.secondary.backgroundLight} 90%)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'relative',
          // Optional: Add a semi-transparent overlay for extra softness
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.3)', // White overlay with 30% opacity
            pointerEvents: 'none',
          },
        }}
      >
      <Container
        maxWidth={false} 
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          borderRadius: 4,
          boxShadow: 5,
          overflow: 'hidden',
          width: '100%',
          maxWidth: { xs: '90%', sm: 500, md: 600 }, 
          m: 2,
        }}
      >
        {/* Left side with image 
        <Box
          sx={{
            flex: 1,
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            background: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
            color: theme.palette.secondary.contrastText,
            p: 4,
          }}
        >
          <Box sx={{ textAlign: 'center', maxWidth: 300 }}>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
              Join Us Today!
            </Typography>
            <Typography variant="body1">
              Create an account to start building your optimized resume.
            </Typography>
            <Box
              component="img"
              src={ImgSignup}
              alt="Sign Up"
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: 300,
                mt: 4,
                mx: 'auto',
              }}
            />
          </Box>
        </Box>*/}

        {/* Right side with form */}
        <Box
          sx={{
            flex: 1,
            p: { xs: 3, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            component="img"
            src={ImgLogo}
            alt="Logo"
            sx={{
              pb: 2,
              maxWidth: 150,
            }}
          />
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
            Create Your Resumefactory Account
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: 400,
              mx: 'auto'
            }}
          >
            <PrimaryTextInput
              autoFocus
              label="Name"
              name="name"
              required
              value={username}
              onChange={onChangeUsername}
              error={error.usernameError !== ''}
              helperText={error.usernameError}
              fullWidth
              sx={{ mb: 2 }}
            />
            <PrimaryTextInput
              label="Email"
              name="email"
              required
              value={email}
              onChange={onChangeEmail}
              error={error.emailError !== ''}
              helperText={error.emailError}
              fullWidth
              sx={{ mb: 2 }}
            />
            <PrimaryTextInput
              label="Password"
              name="password"
              required
              value={password}
              onChange={onChangePassword}
              type={showPassword ? 'text' : 'password'}
              error={error.passwordError !== ''}
              helperText={error.passwordError}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
            />
            {/* Display password strength */}
            {passwordScore !== null && (
              <Box sx={{position: 'relative', minHeight: theme.spacing(8)}}>
                <Box p={1} sx={{border: '1px solid ' + theme.palette.secondary.main,
                  position: 'absolute',
                  background: 'white',
                  zIndex: 1000,
                  width: '100%',
                  borderRadius: theme.spacing(1)}}>
                  <Typography variant="body2" pb={1}>
                    Password Strength: {['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'][passwordScore]}
                  </Typography>
                  {passwordScoreFeedback?.warning && (
                    <Typography variant="body2" color="warning.main" pb={1}>
                      Warning: {passwordScoreFeedback?.warning}
                    </Typography>
                  )}
                  {passwordScoreFeedback?.suggestions &&
                    passwordScoreFeedback?.suggestions?.length > 0 && (
                      <>
                        <Typography variant="body2">Suggestions:</Typography>
                        <ul>
                          {passwordScoreFeedback?.suggestions?.map((suggestion: any, index: any) => (
                            <Typography variant="body2" >
                              <li key={index}>{suggestion}</li>
                            </Typography>
                          ))}
                        </ul>
                      </>
                    )}
                  <Box display="flex" flexDirection="row" mt={1} sx={{justifyContent: 'space-between'}}>
                    {[0, 1, 2, 3, 4].map((index) => (
                      <LinearProgress
                        key={`progress-${index}`}
                        variant="determinate"
                        value={100}
                        sx={{ '& > span': {background: sectionColors[index]}}}
                        style={{ height: '5px', marginBottom: '2px', width: '15%' }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
            <PrimaryTextInput
              label="Confirm Password"
              name="confirmPassword"
              required
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              type={showPassword ? 'text' : 'password'}
              error={error.confirmPasswordError !== ''}
              helperText={error.confirmPasswordError}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
            />
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              By signing up, you agree to our{' '}
              <Link
                component={RouterLink}
                to="/terms"
                rel="noopener"
                sx={{ color: theme.palette.primary.main }}
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                component={RouterLink}
                to="/privacy"
                rel="noopener"
                sx={{ color: theme.palette.primary.main }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                loading={loading}
                type="primary"
                label="Sign Up"
                onClick={onSignUp}
                sx={{
                  mt: 3,
                  width: '100%',
                  backgroundColor: theme.button.primary.backgroundColor,
                  color: theme.button.primary.color,
                  fontSize: theme.typography.pxToRem(16),
                  textTransform: theme.button.primary.textTransform,
                  boxShadow: theme.button.primary.boxShadow,
                  '&:hover': {
                    backgroundColor: theme.button.primary['&:hover']?.backgroundColor,
                    boxShadow: theme.button.primary['&:hover']?.boxShadow,
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">
              Already have an account?{' '}
              <Link
                onClick={onRedirectToLogin}
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Log In
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
      {/* Dialogs and Alerts */}
      <ConfirmDialogBox
        title={`Hi ${username},`}
        message="Thank you for registering with ResumeFactory.ai. Please check your email and click the verification link. Have a good day!"
        open={showConfirmDialogBox}
        primaryButton={{ label: 'OK', onClick: onRedirectToLogin }}
        fullWidth
      />
      <SnackAlert
        open={alertState.open}
        severity={alertState.severity}
        message={alertState.message}
        duration={alertState.duration}
        onClose={onCloseAlert}
      />
    </Box>
  );
};

export default Signup;
