import React, { useEffect, useState } from 'react';
import { Chip, TextField, ClickAwayListener, ChipOwnProps, Box } from '@mui/material';
import { formDiffGreenColor } from './styles';

interface Props {
    label: string;
    onLabelChange?: (label: string) => void;
    onDelete?: () => void;
    existInDiffOrigin?: boolean;
    showDiff: boolean;
    // ...props;
}

const EditableChip: React.FC<ChipOwnProps & Props> = ({ label, onLabelChange, onDelete, existInDiffOrigin, showDiff, ...props }) => {
  
  const [isEditing, setIsEditing] = useState(!label);

  const [labelValue, setLabelValue] = useState(label);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event: any) => {
    setLabelValue(event.target.value);
  };

  const textFieldRef = React.useRef(null);


  const handleClickAway = (event: any) => {
    if (event.target.hasAttribute('EditableChipPreventclickaway')) {
      return;
    }
   
    setIsEditing(false);
    if (labelValue !== label && onLabelChange) {
        onLabelChange(labelValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onLabelChange && onLabelChange(labelValue);
    }
  };

  useEffect(() => {
    if (!isEditing && labelValue === '') {
      onDelete && onDelete();
    }

    // TODO: add autofocus functionality to the textfield.
    // if (isEditing) {
    //   textFieldRef.current && textFieldRef.current.childNodes[0].childNodes[0].focus()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  

  return (
    (<ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {isEditing ? (
          <TextField
            value={labelValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            size="small"
            style={{ width: '100%', minWidth: '225px' }}
            inputProps={{ style: { padding: '4.5px 14px' }, EditableChipPreventclickaway: '', autofocus: true }}
            ref={textFieldRef}
            multiline={true}
          />
        ) : (
          <Chip
            label={label}
            onDelete={onDelete}
            onDoubleClick={handleDoubleClick}
            {...props}
            sx={{ m:'1px', maxWidth: '70vw' }}
            style={{ backgroundColor: (!existInDiffOrigin && showDiff) ? formDiffGreenColor : 'unset' }}
          />
        )}
      </Box>
    </ClickAwayListener>
  ));
};

export default EditableChip;
