import {  Box, Link, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UserPreferences from "../Components/UserPreferences";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import FeedbackForm from "../Components/FeedbackForm";



export default function FeedbackPage({ isLoggedIn }: { isLoggedIn: boolean }) {

  const navigate = useNavigate();
  const params = useParams();

  return (
    <Box pt={3} pl={2} sx={{
      '& form': { width: '700px' },
      '& form textarea': { height: '276px !important' },
      '& form div div div': { background: 'white' },
    }}>

      { isLoggedIn ?
        <>
          <Typography variant="h6" pl={1}>
            Please give us your feedback!
          </Typography>
          <FeedbackForm onSubmitCompleted={() => {}}/>
        </>
      :
      <>
        <Typography variant="body1" pl={1}>
            Please <Link sx={{cursor: 'pointer'}} onClick={() => navigate('/login')} >login</Link> to give feedback or feel free to send it via email.
        </Typography>
      </>
      }
    </Box>
  );
}
