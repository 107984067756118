
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CognitoUserPool from 'amazon-cognito-identity-js';

import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoUserSession, ICognitoUserSessionData, CognitoIdToken, CognitoAccessToken } from 'amazon-cognito-identity-js';
import UserPool from '../Configs/UserPool';
import { useNavigate } from 'react-router-dom';

const EmailVerify = () => {
  const [status, setStatus] = useState('initial');
  const [error, setError] = useState<string|null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const confirmVerificationCode = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const username = searchParams.get('username');
      const code = searchParams.get('confirmation_code');

      if (!username || !code) {
        setError('Invalid or missing parameters.');
        setStatus('error');
        return;
      }

      setStatus('loading');

      const userData = {
        Username: username,
        Pool: UserPool
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmRegistration(code, true, (err: any, result: any) => {
        if (err) {
          console.log('err:', err)
          setError(`
            Sorry, error happened, feel free to try again or contact to our support.
            </br>
            Error message: <b>${err?.message}</b>
          `);
          setStatus('error');
          return;
        }

        setStatus('success');

        setTimeout(() => {
          navigate('/login');
        }, 3000)
      });
    };

    confirmVerificationCode();
  }, []);

  const renderContent = () => {
    switch (status) {
      case 'initial':
        return <Typography>Loading...</Typography>;
      case 'loading':
        return <CircularProgress />;
      case 'error':
        return (
          <Box sx={{maxWidth: '750px'}} pt={2}>
            {/* @ts-ignore */}
            <Typography color='error' dangerouslySetInnerHTML={{ __html: error }}></Typography>
          </Box>
        );
      case 'success':
        return (
          <Box>
            <Typography>Thank you for verifying your email!</Typography>
            <Typography>Redirecting to login page...</Typography>
          </Box>
        );
      default:
        return <Typography>Unknown status</Typography>;
    }
  };

  return (
    <Box
      pt={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Typography variant="h4" gutterBottom>Welcome to ResumeFactory.ai</Typography>
      <Typography>We're just verifying your email address to get you all set up. </Typography>
      {renderContent()}
    </Box>
  );
};

export default EmailVerify;
