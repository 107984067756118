import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoUserSession, ICognitoUserSessionData, CognitoIdToken, CognitoAccessToken } from 'amazon-cognito-identity-js';
import UserPool from '../../Configs/UserPool';


export const awsOnUserSignup = (email: string, password: string, name: string): Promise<any> => {
  return new Promise((resolve, reject): void => {
    const attributeList: CognitoUserAttribute[] = [];
    const userNameObject: { Name: string; Value: string } = { Name: "nickname", Value: name };
    const userNameAttribute: CognitoUserAttribute = new CognitoUserAttribute(userNameObject);
    attributeList.push(userNameAttribute);

    UserPool.signUp(email, password, attributeList, [], (err, data: any) => {
      if (err) {
        reject(err);
      } else {
        // Notify user to confirm their email
        resolve({ message: 'Signup successful. Please check your email to confirm your account.' });
      }
    });
  });
};



export const awsOnUserSignIn = (email: string, password: string): any => {
  return new Promise(async (resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    const credentials = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    user.authenticateUser(credentials, {
      onSuccess: async (data) => {
        const accessToken = data.getAccessToken().getJwtToken();
        const payload = data.getIdToken().payload;
        const cognitoId = payload['cognito:username']; // cognito_id
        const name = payload['nickname'] || payload['name'] || ''; // Use 'name' if 'nickname' is not available, fallback to ''
        const email = payload['email'];

        const groups = payload?.["cognito:groups"] || [];
        const isAdmin = groups.includes('ADMIN');

        const role = isAdmin ? 'ADMIN' : 'USER';

        try {
          // Load session data
          const user = { id: cognitoId, email, name, role, isAdmin, image: '', accessToken: accessToken };
          resolve(user);
        } catch (err) {
          console.error("Error saving user to DB:", err);
          reject(err);
        }
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve(data);
      }
    });
  });
};


export const awsOnUserSignOut = (): any => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      resolve('success');
    }
    else {
      reject();
    }
  });
}


export const awsOnChangePassword = (email: string, oldPassword: string, newPassword: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    // Authenticate the user with the old password first
    user.authenticateUser(
      new AuthenticationDetails({
        Username: email,
        Password: oldPassword
      }),
      {
        onSuccess: () => {
          // If authentication is successful, proceed to change the password
          user.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve({ message: 'Password change successful.' });
            }
          });
        },
        onFailure: (err) => {
          reject(err);
        }
      }
    );
  });
};

export interface IUser {
  id: string,
  isLoggedIn: boolean,
  apiUserId: string,
  accessToken: string,
  membershipType?: string,
  membershipActiveUntil?: string,
}

export const hasValidSession = (user: IUser) => {
  const awsStoredUser = UserPool.getCurrentUser();
  if (!awsStoredUser) {
    return false;
  }

  const cognitoIdToken = new CognitoIdToken({ IdToken: user.accessToken });
  const cognitoAccessToken = new CognitoAccessToken({ AccessToken: user.accessToken });
  const sessionData: ICognitoUserSessionData = {
    IdToken: cognitoIdToken,
    AccessToken: cognitoAccessToken,
  }
  const cognitoUserSession = new CognitoUserSession(sessionData);
  const isValid = cognitoUserSession.isValid();
  console.log('hasValidSession - ', isValid);
  return user.isLoggedIn && isValid;
}

export const hasExpiredSession = (user: IUser) => {
  return user.isLoggedIn && !hasValidSession(user);
}



/*
{
    "user": {
        "username": "testUser@ghmail.com",
        "pool": {
            "userPoolId": "ap-southeast-1_HOChu67x3",
            "clientId": "1sm323tk3hvhgm7ns080fnk5dd",
            "client": {
                "endpoint": "https://cognito-idp.ap-southeast-1.amazonaws.com/",
                "fetchOptions": {}
            },
            "advancedSecurityDataCollectionFlag": true,
            "storage": {
                "_grecaptcha": "09AJgLGVAwwovQV5a5N4ubO8QYxHnDSn1lAnJ0zMqHlkCclTzy_UwqGp_N-QY0TB3qsSml4RtJPPlaYCAQ8g5QSYo",
                "persist:root": "{\"user\":\"{\\\"id\\\":\\\"\\\",\\\"name\\\":\\\"\\\",\\\"email\\\":\\\"\\\",\\\"image\\\":\\\"\\\",\\\"accessToken\\\":\\\"\\\",\\\"isLoggedIn\\\":false,\\\"apiUserId\\\":\\\"\\\",\\\"isAdmin\\\":false,\\\"impersonatedId\\\":\\\"\\\",\\\"role\\\":\\\"\\\"}\",\"_persist\":\"{\\\"version\\\":-1,\\\"rehydrated\\\":true}\"}"
            }
        },
        "Session": null,
        "client": {
            "endpoint": "https://cognito-idp.ap-southeast-1.amazonaws.com/",
            "fetchOptions": {}
        },
        "signInUserSession": null,
        "authenticationFlowType": "USER_SRP_AUTH",
        "storage": {
            "_grecaptcha": "09AJgLGVAwwovQV5a5N4ubO8QYxHnDSn1lAnJ0zMqHlkCclTzy_UwqGp_N-QY0TB3qsSml4RtJPPlaYCAQ8g5QSYo",
            "persist:root": "{\"user\":\"{\\\"id\\\":\\\"\\\",\\\"name\\\":\\\"\\\",\\\"email\\\":\\\"\\\",\\\"image\\\":\\\"\\\",\\\"accessToken\\\":\\\"\\\",\\\"isLoggedIn\\\":false,\\\"apiUserId\\\":\\\"\\\",\\\"isAdmin\\\":false,\\\"impersonatedId\\\":\\\"\\\",\\\"role\\\":\\\"\\\"}\",\"_persist\":\"{\\\"version\\\":-1,\\\"rehydrated\\\":true}\"}"
        },
        "keyPrefix": "CognitoIdentityServiceProvider.1sm323tk3hvhgm7ns080fnk5dd",
        "userDataKey": "CognitoIdentityServiceProvider.1sm323tk3hvhgm7ns080fnk5dd.testUser@ghmail.com.userData"
    },
    "userConfirmed": false,
    "userSub": "aa7fd93e-1a40-4d3e-ab16-ea1632cd69cb",
    "codeDeliveryDetails": {
        "AttributeName": "email",
        "DeliveryMedium": "EMAIL",
        "Destination": "t***@g***.com"
    }
}


*/