import React from "react";
import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

const PREFIX = 'Progressbar';

const classes = {
    root: `${PREFIX}-root`,
    barStyle: `${PREFIX}-barStyle`
};

const Root = styled('div')(() => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%'
    },
    [`& .${classes.barStyle}`]: {
        marginTop: 10,
        marginRight: 10,
        width: '100%'
    },
}));

interface Props {
    isLoading: boolean
    value?: number
    withValue: boolean
}

const Progressbar: React.FC<Props> = ({ isLoading, value, withValue }) => {


    if (isLoading) {
        if (withValue) {
            return (
                <Root className={classes.root}>
                    <LinearProgress variant='determinate' color="primary" value={value} className={classes.barStyle} />
                    <div>{value}%</div>
                </Root>
            );
        }
        else {
            return (
                <Root className={classes.root}>
                    <LinearProgress color="primary" className={classes.barStyle} />
                </Root>
            );
        }

    }

    return null;
}


export default Progressbar;