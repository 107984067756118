import { httpOnGetUserBalance, httpOnUserBalanceUpgradeMembership } from '../../Services/Http';
import {
    USER_BALANCE_LOADING,
    USER_BALANCE_LOADING_FAILED,
    USER_BALANCE_SAVE,
    USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS,
    USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED,
} from './Types';

export const onUserBalanceLoadingAction = (payload: boolean): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_LOADING, payload: payload });
    }
}

export const onUserBalanceLoadingFailedAction = (): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_LOADING_FAILED });
    }
}

export const onUserBalanceSaveAction = (payload: any): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_SAVE, payload: payload });
    }
}

interface UserBalanceUpgradeMembershipPayload {
    userId: string,
    membershipType: string,
}

export const onUserBalanceUpgradeMembershipAction = (payload: UserBalanceUpgradeMembershipPayload): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS, payload: true });

        const requestBody = {
            userId: payload.userId,
            membershipType: payload.membershipType,
        };

        // TODO: consider using redux-thunk

        httpOnUserBalanceUpgradeMembership(requestBody).then((response: any) => {
            dispatch({ type: USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS, payload: false });
            dispatch({ type: USER_BALANCE_SAVE, payload: response.data });
        }).catch((error: any) => {
            dispatch({ type: USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS, payload: false });
            console.log(error);
        });

    }
}

interface checkoutSessionSecretPayload {
    checkoutSessionSecret: string
}

export const onUserBalanceCheckoutSessionSecretReceivedAction = (payload: checkoutSessionSecretPayload): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED, payload: payload });
    }
}


export const onUserBalanceGetAction = (userId: string): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_BALANCE_LOADING, payload: true });

        httpOnGetUserBalance(userId).then((response: any) => {
            dispatch({ type: USER_BALANCE_LOADING, payload: false });
            dispatch({ type: USER_BALANCE_SAVE, payload: response.data });
        }).catch((error: any) => {
            dispatch({ type: USER_BALANCE_LOADING, payload: false });
            dispatch({ type: USER_BALANCE_LOADING_FAILED });
            console.log(error);
        });
    }
}

