

import UserPool from '../../Configs/UserPool';

export const awsOnGetSession = (): any => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();

    if (user) {
      user.getSession((err: any, session: any) => {
        if (err) {
          reject(err);
          return;
        }

        if (!session.isValid()) {
          reject(new Error("Session is not valid"));
          return;
        }

        const { accessToken: { jwtToken, payload: { username } }, idToken: { payload: { email, nickname } } } = session;
        const userData = { id: username, email: email, name: nickname, image: '', accessToken: jwtToken };
        resolve(userData);
      });
    } else {
      reject(new Error("No current user found"));
      return
    }
  });
}



const authHeaderName = 'Authorization';
export var setAuthHeader = async function (config: any) {
  try {
    const session: any = await awsOnGetSession();
    config.headers[authHeaderName] = `Bearer ${session.accessToken}`;
    return config;
  } catch (err) {
    console.error("Error setting auth header:", err);
    throw err;
  }
}