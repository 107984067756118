// src/pages/Landing.tsx

import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  Fade,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Build, Feedback, DesignServices } from '@mui/icons-material';
import FAQSection from '../Components/FAQSection';
import TestimonialsCarousel from '../Components/TestimonialsCarousel';
import HeroSection from '../Components/HeroSection';
import InfoBox1 from '../Components/InfoBox1';
import InfoBox2 from '../Components/InfoBox2';
import SavePdfGif from '../Assets/edit-save-pdf.gif';
import EditTailoredGif from '../Assets/edit-tailor-shorter.gif';
import UploadGif from '../Assets/upload-combined.gif';
import { useState, useEffect } from 'react';
import { useInView } from '../Hooks/useInView';

const isWaitlistMode = !!process.env.REACT_APP_IS_WAITLIST_MODE;

// Define the type for the user prop
interface User {
  id: string | null;
  // Add other user properties as needed
}

interface LandingProps {
  user: User;
}


export default function Landing({ user }: LandingProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const isAuthenticated = !!user.id;

  const [showUploadGif, setShowUploadGif] = useState(false);
  const [showEditGif, setShowEditGif] = useState(false);
  const [showSaveGif, setShowSaveGif] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);

  // Create ref for the container
  const [containerRef, isInView] = useInView({
    threshold: 0.3, // Trigger when 30% of the element is visible
  });

  useEffect(() => {
    console.log('Effect running - isInView:', isInView, 'animationStarted:', animationStarted);
    
    if (isInView && !animationStarted) {
      console.log('Starting animation sequence');
      setAnimationStarted(true);
      setShowUploadGif(true);

      const timer1 = setTimeout(() => {
        console.log('Starting second animation');
        setShowEditGif(true);
      }, 4000);

      const timer2 = setTimeout(() => {
        console.log('Starting third animation');
        setShowSaveGif(true);
      }, 10000);

      // Only clean up when the component unmounts or when isInView becomes false
      return () => {
        if (!isInView) {
          console.log('Cleaning up timers');
          clearTimeout(timer1);
          clearTimeout(timer2);
        }
      };
    }
  }, [isInView, animationStarted]);

  const handleGetStartedClick = () => {
    if (isAuthenticated) {
      navigate('/app');
    } else {
      navigate('/signup');
    }
  };

  return (
    <Box>
      {/* Hero Section */}

      <HeroSection
        handleGetStartedClick={handleGetStartedClick}
        buttonText={isAuthenticated ? "Go to your Dashboard" : "Get Started For Free"}
        isWaitlistMode={isWaitlistMode!}
      />

      {/* Resume Analyzer Section */}
      <Box
        textAlign="center"
        py={{ xs: 4, md: 8 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '30vh',   
            md: '40vh',   
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center',
          flex: 1,
          mb: 6,
          background: `linear-gradient(0deg, 
            ${theme.palette.background.paper} 0%, 
            ${theme.palette.augmentColor({
              color: theme.palette.secondary,
              mainShade: 'light',
            }).light}80 30%, 
            ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Box  py={{xs:1,md:3}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            Tailor your Resume for any Job in Minutes
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="textPrimary"
          paragraph
          sx={{ maxWidth: 500, mx: 'auto' }}
        >
          Upload your resume for a <b>free resume, tailored for any job</b>.<br/>Our AI will instantly evaluate your resume, optimize it for your specific role, and provide the insights you need to improve it further.
          <br/>Then <b>download your ATS-optimized resume</b> in PDF format.
        </Typography>

        <Box
          ref={containerRef as React.RefObject<HTMLDivElement>}
          sx={{
            mt: 4,
            // mb: 6,
            width: '100%',
            height: 400,
            backgroundColor: theme.palette.grey[200],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            color: theme.palette.text.secondary,
            borderRadius: 2,
            p: 2,
          }}
        >
          <Fade in={showUploadGif} timeout={1000}>
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>Upload Resume</Typography>
              <Box
                component="img"
                src={UploadGif}
                sx={{
                  width: '100%',
                  height: 'calc(100% - 48px)',
                  objectFit: 'contain',
                  borderRadius: 1,
                }}
                alt="Upload resume demonstration"
              />
            </Box>
          </Fade>

          <Fade in={showEditGif} timeout={1000}>
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>Add Job and Approve Changes</Typography>
              <Box
                component="img"
                src={EditTailoredGif}
                sx={{
                  width: '100%',
                  height: 'calc(100% - 48px)',
                  objectFit: 'contain',
                  borderRadius: 1,
                }}
                alt="Edit resume demonstration"
              />
            </Box>
          </Fade>

          <Fade in={showSaveGif} timeout={1000}>
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>Download Your Optimized Resume</Typography>
              <Box
                component="img"
                src={SavePdfGif}
                sx={{
                  width: '100%',
                  height: 'calc(100% - 48px)',
                  objectFit: 'contain',
                  borderRadius: 1,
                }}
                alt="Save PDF demonstration"
              />
            </Box>
          </Fade>
        </Box>
      </Box>

      {/* Informational Box 1 */}
      <InfoBox1 />

      {/* How It Works Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '25vh',   
            md: '30vh',   
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          background: `linear-gradient(180deg, 
            ${theme.palette.background.default} 0%, 
            ${theme.palette.augmentColor({
              color: theme.palette.secondary,
              mainShade: 'light',
            }).light}40 30%,  
            ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Box  py={{xs:1,md:4}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            How It Works
          </Typography>
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              title: 'Step 1: Upload Your Resume (PDF Format)',
              description:
                'Your journey begins by uploading your master resume. This document should contain all your experience, skills, and education.',
            },
            {
              title: 'Step 2: Paste the Job Description You Want',
              description:
                'Our AI analyzes the job description, mapping the essential skills and qualifications needed for the role—allowing you to align your resume with what recruiters are looking for.',
            },
            {
              title: 'Step 3: Download Your Optimized Resume',
              description:
                'Within moments, our AI will enhance your resume to match keywords from the job description while ensuring your qualifications are accurately represented.',
            },
          ].map((step, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  maxWidth: 350,
                  mx: 'auto',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  p: 3,
                  backgroundColor: theme.palette.background.default,
                  height: '100%', // Ensures consistent height
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {step.title}
                </Typography>
                <Typography 
                   variant="body1" 
                   color="gray.600"
                  >
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Key Features Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '25vh',   
            md: '30vh'
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box  py={{xs:1,md:4}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            Key Features
          </Typography>
        </Box>
        <Grid container spacing={6} justifyContent="center">
          {[
            {
              icon: <Build fontSize="large" color="primary" />,
              title: 'AI-Powered Resume Optimization',
              description:
                'Our advanced AI analyzes both your resume and the job description to ensure your content includes the most impactful, job-specific keywords and phrases. This boosts your chances of passing through ATS filters and landing an interview.',
            },
            {
              icon: <Feedback fontSize="large" color="primary" />,
              title: 'Instant Feedback for Immediate Improvement',
              description:
                "Receive real-time feedback on how well your resume aligns with the desired job description. With actionable insights, you'll know exactly where to improve, helping you make adjustments that significantly increase your chances of catching the eye of recruiters.",
            },
            {
              icon: <DesignServices fontSize="large" color="primary" />,
              title: 'Modern, Professional Templates',
              description:
                'Select from a wide variety of professionally designed, ATS-friendly templates that not only look great but are tailored to highlight your skills and experience in the most effective way possible.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  maxWidth: 350,
                  mx: 'auto',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  p: 3,
                  backgroundColor: theme.palette.background.default,
                  height: '100%', // Ensures consistent height
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                {feature.icon}
                <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                  {feature.title}
                </Typography>
                <Typography 
                   variant="body1" 
                   color="gray.600"
                  >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {/* CTA Button */}
        <Box textAlign="center" py={5}>

          { !isWaitlistMode &&
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStartedClick}
            sx={{...theme.button.primary}}
          >
            {isAuthenticated ? "Go to your Dashboard" : "Get Started Now"}
          </Button>
          }
        </Box>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ mt: 8 }}>
        <TestimonialsCarousel />
      </Box>

      {/* Informational Box 2 */}
      <InfoBox2 />

      {/* FAQ Section */}
      <Box sx={{ mt: 8 }}>
        <FAQSection />
      </Box>

      {/* Call to Action Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
          Start Your Journey to Success
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ maxWidth: 600, mx: 'auto' }}
        >
          Don't let a poorly written resume hold you back. With ResumeFactory.ai, you'll have the tools you need to create a resume that stands out.
        </Typography>
        { !isWaitlistMode &&
        <Button
          variant="contained"
          size="large"
          onClick={handleGetStartedClick}
          sx={{...theme.button.primary}}
        >
          {isAuthenticated ? "Go to your Dashboard" : "Start Now"}
        </Button>
        }
      </Box>
    </Box>
  );
}