// src/components/TestimonialsCarousel.tsx

import React from 'react';
import { Box, Typography, Avatar, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Pagination,
  Autoplay,
  Navigation,
  Keyboard,
  EffectFade,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

interface Testimonial {
  name: string;
  role: string;
  message: string;
  avatarUrl: string;
}

const testimonials: Testimonial[] = [
  {
    name: 'Jane Doe',
    role: 'Software Engineer',
    message:
      'I was struggling to get noticed by top tech companies, but ResumeFactory.ai changed that. Within minutes, I had a tailored resume that perfectly matched the jobs I was applying for. In just a few weeks, I landed interviews at several leading companies!',
    avatarUrl: 'https://randomuser.me/api/portraits/women/44.jpg',
  },
  {
    name: 'John Smith',
    role: 'Marketing Specialist',
    message:
      'The AI optimization feature on ResumeFactory.ai is an absolute game-changer. It helped my resume stand out by highlighting the exact keywords recruiters are looking for. I\'ve seen a huge increase in responses since I made the switch.',
    avatarUrl: 'https://randomuser.me/api/portraits/men/46.jpg',
  },
  {
    name: 'Emily Johnson',
    role: 'Data Analyst',
    message:
      'Creating a professional resume that accurately reflects my skills used to be a challenge. With ResumeFactory.ai, the process was easy, and the final result truly showcases my experience in a way that gets noticed.',
    avatarUrl: 'https://randomuser.me/api/portraits/women/68.jpg',
  },
  {
    name: 'Michael Brown',
    role: 'Product Manager',
    message:
      "The instant feedback I received from ResumeFactory.ai was incredibly helpful. It gave me actionable insights that made my resume much more effective, and I started getting more attention from hiring managers right away.",
    avatarUrl: 'https://randomuser.me/api/portraits/men/32.jpg',
  },
  // Add more testimonials as needed
];

export default function TestimonialsCarousel() {
  const theme = useTheme();

  return (
    <Box
      textAlign="center"
      py={{ xs: 6, md: 10 }}
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
        Send us feedback so we can replace these placeholder testimonials with real ones! 😊
      </Typography>
      <Box maxWidth="md" mx="auto">
        <Swiper
          modules={[
            Pagination,
            Autoplay,
            Navigation,
            Keyboard,
            EffectFade,
          ]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop={true}
          navigation={true}
          keyboard={{ enabled: true }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          style={{ padding: theme.spacing(0, 5) }}
          aria-label="Testimonials Carousel"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              key={index}
              aria-label={`Testimonial from ${testimonial.name}`}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                px={3}
                py={4}
                sx={{
                  minHeight: '200px',
                  margin: '0 auto',
                }}
              >
                <Avatar
                  src={testimonial.avatarUrl}
                  alt={testimonial.name}
                  sx={{ width: 80, height: 80, mb: 2 }}
                  imgProps={{ loading: 'lazy' }}
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontStyle: 'italic',
                    maxWidth: 600,
                    color: theme.palette.text.primary,
                  }}
                >
                  "{testimonial.message}"
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  — {testimonial.name}, {testimonial.role}
                </Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
}
