import React, { useState } from "react";
import {  Box, IconButton, Typography, Theme,styled } from "@mui/material";

import {
  DeleteForever,
  Add as IconAdd,
  Undo,
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray, } from "react-hook-form";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import theme from "../../theme";
import { getByPath, getId } from "./utils";


const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));



interface ExperienceClientProjectsProps {
  control: any,
  fieldPath: string,
  _required?: boolean,
  diffOriginData?: any;
  showDiff: boolean;
}


const ExperienceClientProjects: React.FC<ExperienceClientProjectsProps> = ({ control, fieldPath, _required, diffOriginData, showDiff }) => {

  const clientProjects = getByPath(control._formValues, fieldPath);

  // eslint-disable-next-line 
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: fieldPath, // unique name for your Field Array
  });

  const path = fieldPath;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updetaild clientProjects variable in the component

  return <Root className={classes.root}>
    <Typography>Client Projects
      <IconButton onClick={() => {append({})}}
        disabled={clientProjects?.length > 0 && !(clientProjects[clientProjects.length -1]?.client_name || clientProjects[clientProjects.length -1]?.detail)}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex'}}>
        <Box sx={{flexGrow: 1, ...borderedSectionStyles}}>

          <Controller
            name={`${path}.${index}.client_name`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="client_name"
                  label="Client name"
                  onChange={(event: any) => {onChange(event); setState(state+1)}}
                  value={value || ''}
                  required={_required && true}
                  multiline={true}
                />
              </Box>
            )}
          />

          <Controller
            name={`${path}.${index}.detail`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="detail"
                  label="Details"
                  onChange={(event: any) => {onChange(event); setState(state+1)}}
                  value={value || ''}
                  required={_required && false}
                  multiline={true}
                />
              </Box>
            )}
          />


        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {remove(index)}}>
            <DeleteForever/>
          </IconButton>
        </Box>

      </Box>
    ))}


    {/* Removed clientProjects */}
    { showDiff && (getByPath(diffOriginData, path)?.length > fields?.length) &&
      [...(getByPath(diffOriginData, path) || [])].slice(fields?.length).map((field: any, index: number) => (

      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles,
          position: 'relative',
          border: `6px dashed ${formDiffRedColor}`
        }}>

          <Box>
            <Typography variant="body2" sx={{background: formDiffRedColor, p:1, borderRadius: theme.spacing(0.5)}}>
              Deleted Client Projects{field?.client_name ? ':' : ''} {field?.client_name}
            </Typography>
          </Box>

          <Box>
            <PrimaryTextInput
              name="client_name"
              label="Client name"
              value={ field?.client_name || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="detail"
              label="detail"
              value={ field?.detail || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {append(field)}}>
            <Undo/>
          </IconButton>
        </Box>

      </Box>
    ))}




  </Root>
}



export default ExperienceClientProjects;