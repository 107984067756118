import React from 'react';
import ReactDOM from 'react-dom/client';
import { store, persistor } from './Redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';



const bodyBackgroundColor = theme.palette.secondary.backgroundLight;
// const bodyBackgroundColor = '#e8ece2'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ body: { background: bodyBackgroundColor } }} />
            <App />
          </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
