import {
    USER_SAVE,
    USER_REMOVE,
    USER_SAVE_CREDENTIALS,
    USER_ACTIVE_IMPERSONATION,
    USER_DEACTIVE_IMPERSONATION
} from './Types';

interface Credentials {
    provider: string,
    roleArn: string,
    region: string
}

interface State {
    id: string,
    name: string,
    email: string,
    image: string,
    role: string,
    accessToken: string,
    isLoggedIn: boolean,
    apiUserId: string, /*Attribute is used in the APIs. Whenimpersonation mode activated impersonate user id will be the apiUserId, Otherwise normal id.*/
    impersonatedId: string,
    credentials: Credentials[]
}

interface Action {
    type: string,
    payload: any
}

const initialState: State = {
    id: '',
    name: '',
    email: '',
    image: '',
    role: '',
    accessToken: '',
    isLoggedIn: false,
    apiUserId: '',
    impersonatedId: '',
    credentials: []
}

const userReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case USER_SAVE: return {
            ...state,
            ...action.payload,
            isLoggedIn: true,
            apiUserId: action.payload.id,
        }
        case USER_REMOVE: return {
            ...state,
            ...initialState
        }
        case USER_ACTIVE_IMPERSONATION: return {
            ...state,
            impersonatedId: action.payload,
            apiUserId: action.payload,
        }
        case USER_DEACTIVE_IMPERSONATION: return {
            ...state,
            impersonatedId: '',
            apiUserId: state.id,
        }
        case USER_SAVE_CREDENTIALS: return {
            ...state,
            credentials: action.payload
        }
        default: return state
    }
}

export default userReducer;