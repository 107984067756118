import React from "react";
import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from 'react'; 
import { PaletteColorOptions } from '@mui/material/styles';
import { lighten } from "@mui/system";

declare module '@mui/material/styles' {
  interface Palette {
    highlight: PaletteColor;
  }
  interface PaletteOptions {
    highlight?: PaletteColorOptions;
  }
  interface PaletteColor {
    backgroundLight?: string;
    backgroundDark?: string;
  }
}

//
// Adjusted Palette
//
// Primary: Ultramarine Blue
// Secondary: Softer Teal (more refined, modern tone)
// Highlight: Modern Amber/Golden Yellow
// Remove purple for visited links, instead choose tonal blue variations
//

const palette = {
  primary: {
    main: '#345BFF', 
    light: '#6C85FF',
    dark: '#0031CA',
    contrastText: '#FFFFFF',
    backgroundLight: lighten('#345BFF', 0.9), 
  },
  secondary: {
    main: '#14B8A6', 
    light: '#2DD4BF',
    dark: '#0D9488',
    contrastText: '#FFFFFF',
    backgroundLight: lighten('#14B8A6', 0.9),
  },
  highlight: {
    main: '#FBBF24', // Modern amber
    light: '#FCD34D',
    dark: '#D97706',
    contrastText: '#000000',
  },
  background: {
    default: '#F5F7FA', 
    paper: '#FFFFFF',
  },
  text: {
    primary: '#111111', 
    secondary: '#6B6B6B',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  grey: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  success: {
    main: '#34D399', 
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FBBF24',
    contrastText: '#000000',
  },
  error: {
    main: '#F87171', 
    dark: '#DC2626',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#3B82F6', 
    contrastText: '#FFFFFF',
  },
  // Updated link colors to be tonal blues
  link: {
    main: '#1D4ED8', 
    contrastText: '#FFFFFF',
    hover: '#3B82F6', 
    visited: '#1E40AF', 
  },
  button: {
    primary: {
      main: '#345BFF',
      contrastText: '#FFFFFF',
      hover: '#0031CA',
      disabled: '#ffffff9e',
    },
    secondary: {
      main: '#14B8A6',
      contrastText: '#FFFFFF',
      hover: '#0D9488',
      disabled: 'rgba(20,184,166,0.5)',
    },
    highlight: {
      main: '#FBBF24',
      contrastText: '#000000',
      hover: '#D97706',
      disabled: 'rgba(251,191,36,0.5)',
    },
    error: {
      main: '#F87171',
      contrastText: '#FFFFFF',
      hover: '#DC2626', 
      disabled: 'rgba(248,113,113,0.5)',
    },
    underline: {
      main: 'transparent',
      contrastText: '#1D4ED8', 
      hover: '#3B82F6',
      disabled: 'rgba(29,78,216,0.5)',
    },
    refresh: {
      main: '#E5E7EB', 
      contrastText: '#6B7280', 
      hover: '#D1D5DB', 
      disabled: 'rgba(107,114,128,0.5)',
    },
    disabled: {
      main: '#9CA3AF', 
      contrastText: '#FFFFFF',
      disabled: 'rgba(156,163,175,0.5)',
    },
    ternary: {
      main: '#FFFFFF',
      contrastText: '#1D4ED8',
      hover: '#F8FAFB',
      disabled: 'rgba(29,78,216,0.5)',
    },
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

  h1: {
    fontSize: '3rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  h2: {
    fontSize: '2.5rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },

  body1: {
    fontSize: '1rem',
    fontWeight: 300,
    letterSpacing: '0.25px',
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 300,
    letterSpacing: '0.25px',
  },

  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.4px',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.4px',
  },

  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
  },

  caption: {
    fontSize: '0.75rem',
    fontWeight: 300,
    letterSpacing: '0.4px',
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '2px',
    textTransform: 'uppercase',
  },
};

declare module '@mui/material/styles' {

  interface ButtonStyle {
    marginTop?: React.CSSProperties['marginTop']
    backgroundColor?: React.CSSProperties['color'];
    background?: React.CSSProperties['background'];
    color: React.CSSProperties['color'];
    fontSize: React.CSSProperties['fontSize'];
    textTransform:  React.CSSProperties['textTransform'];
    width: React.CSSProperties['width'];
    boxShadow?: React.CSSProperties['boxShadow'];
    borderRadius?: React.CSSProperties['borderRadius'];
    '&:hover'?: {
      backgroundColor?: React.CSSProperties['color'];
      background?: React.CSSProperties['background'];
      color?: React.CSSProperties['color'];
      boxShadow?: React.CSSProperties['boxShadow'];
      transform?: React.CSSProperties['transform'];
      transition?: React.CSSProperties['transition'];
    };
    '&:disabled'?: {
      color: React.CSSProperties['color'];
    };
  }

  interface Theme {
    hover: {
      button: {
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      error: {
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      tab: {
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
    };
    fontSize: {
      xs: React.CSSProperties['fontSize'];
      sm: React.CSSProperties['fontSize'];
      md: React.CSSProperties['fontSize'];
      lg: React.CSSProperties['fontSize'];
      xl: React.CSSProperties['fontSize'];
      xxl: React.CSSProperties['fontSize'];
      xxxl: React.CSSProperties['fontSize'];
    };
    
    button: {
      primary: ButtonStyle;
      secondary: ButtonStyle;
      error: ButtonStyle;
      underline: ButtonStyle;
      refresh: ButtonStyle;
      disabled: ButtonStyle;
      ternary: ButtonStyle;
    };
    colors: {
      grey: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
    };
    textInput: {
      fontSize: React.CSSProperties['fontSize'];
      padding: React.CSSProperties['padding'];
      borderRadius: React.CSSProperties['borderRadius'];
      borderWidth: React.CSSProperties['borderWidth'];
      borderColor: React.CSSProperties['borderColor'];
    };
    table: {
      width: React.CSSProperties['width'];
      borderRadius: React.CSSProperties['borderRadius'];
      head: {
        fontSize: React.CSSProperties['fontSize'];
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      body: {
        margin: React.CSSProperties['margin'];
        padding: React.CSSProperties['padding'];
        fontSize: React.CSSProperties['fontSize'];
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      icon: {
        color: React.CSSProperties['color'];
        fontSize: React.CSSProperties['fontSize'];
      };
    };
    container: {
      backgroundColor: React.CSSProperties['color'];
      borderRadius: React.CSSProperties['borderRadius'];
      boxShadow: React.CSSProperties['boxShadow'];
      padding: React.CSSProperties['padding'];
    };
    Loader: {
      color: React.CSSProperties['color'];
    };
    header: {
      color: React.CSSProperties['color'];
      backgroundColor: React.CSSProperties['color'];
      fontSize: React.CSSProperties['fontSize'];
      borderRadius: React.CSSProperties['borderRadius'];
    };
    search: {
      input: {
        fontSize: React.CSSProperties['fontSize'];
      };
      icon: {
        color: React.CSSProperties['color'];
      };
    };
    emptyText: {
      color: React.CSSProperties['color'];
      fontSize: React.CSSProperties['fontSize'];
    };
  }

  interface ThemeOptions {
    hover?: {
      button?: {
        backgroundColor?: CSSProperties['color'];
        color?: CSSProperties['color'];
      };
      error?: {
        backgroundColor?: CSSProperties['color'];
        color?: CSSProperties['color'];
      };
      tab?: {
        backgroundColor?: CSSProperties['color'];
        color?: CSSProperties['color'];
      };
    };
    button?: {
      primary?: ButtonStyle;
      secondary?: ButtonStyle;
      error?: ButtonStyle;
      underline?: ButtonStyle;
      refresh?: ButtonStyle;
      disabled?: ButtonStyle;
      ternary?: ButtonStyle;
    };
    fontSize: {
      xs: React.CSSProperties['fontSize'];
      sm: React.CSSProperties['fontSize'];
      md: React.CSSProperties['fontSize'];
      lg: React.CSSProperties['fontSize'];
      xl: React.CSSProperties['fontSize'];
      xxl: React.CSSProperties['fontSize'];
      xxxl: React.CSSProperties['fontSize'];
    };
    colors: {
      grey: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
      warning: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
    };
    textInput: {
      fontSize: React.CSSProperties['fontSize'];
      padding: React.CSSProperties['padding'];
      borderRadius: React.CSSProperties['borderRadius'];
      borderWidth: React.CSSProperties['borderWidth'];
      borderColor: React.CSSProperties['borderColor'];
    };
    table: {
      width: React.CSSProperties['width'];
      borderRadius: React.CSSProperties['borderRadius'];
      head: {
        fontSize: React.CSSProperties['fontSize'];
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      body: {
        margin: React.CSSProperties['margin'];
        padding: React.CSSProperties['padding'];
        fontSize: React.CSSProperties['fontSize'];
        backgroundColor: React.CSSProperties['backgroundColor'];
        color: React.CSSProperties['color'];
      };
      icon: {
        color: React.CSSProperties['color'];
        fontSize: React.CSSProperties['fontSize'];
      };
    };
    container: {
      backgroundColor: React.CSSProperties['color'];
      borderRadius: React.CSSProperties['borderRadius'];
      boxShadow: React.CSSProperties['boxShadow'];
      padding: React.CSSProperties['padding'];
    };
    Loader: {
      color: React.CSSProperties['color'];
    };
    header: {
      color: React.CSSProperties['color'];
      backgroundColor: React.CSSProperties['color'];
      fontSize: React.CSSProperties['fontSize'];
      borderRadius: React.CSSProperties['borderRadius'];
    };
    search: {
      input: {
        fontSize: React.CSSProperties['fontSize'];
      };
      icon: {
        color: React.CSSProperties['color'];
      };
    };
    emptyText: {
      color: React.CSSProperties['color'];
      fontSize: React.CSSProperties['fontSize'];
    };
  }
}

const _theme: ThemeOptions  = {
  palette: palette,
  typography: typography,

  hover: {
    button: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
    error: {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,
    },
    tab: {
      backgroundColor: palette.primary.dark,
      color: palette.primary.contrastText,
    },
  },

  container: {
    backgroundColor: palette.background.default,
    borderRadius: 10,
    padding: 16,
    boxShadow: '0px 1px 3px -1px #000000',
  },
  table: {
    width: '100%',
    borderRadius: 10,
    body: {
      margin: 0,
      padding: 4,
      color: palette.text.primary,
      backgroundColor: palette.background.default,
      fontSize: 14,
    },
    head: {
      backgroundColor: palette.primary.dark,
      color: palette.primary.contrastText,
      fontSize: 14,
    },
    icon: {
      fontSize: 16,
      color: palette.primary.dark,
    },
  },
  textInput: {
    fontSize: 12,
    padding: 10,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'rgba(0,0,0,0.2)',
  },
  button: {
    primary: {
      marginTop: 4,
      background: `linear-gradient(90deg, ${palette.primary.main}, ${palette.secondary.main})`,
      color: palette.button.primary.contrastText,
      fontSize: 14,
      textTransform: 'none',
      width: 250,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
      '&:hover': {
        background: `linear-gradient(90deg, ${palette.primary.dark}, ${palette.secondary.dark})`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        color: palette.button.primary.contrastText,
        transform: 'scale(1.05)',
        transition: 'transform 0.3s ease-in-out',
      },
      '&:disabled': {
        color: palette.button.primary.disabled,
      },
    },
    secondary: {
      backgroundColor: palette.button.secondary.main,
      color: palette.button.secondary.contrastText,
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: palette.button.secondary.hover,
        color: palette.button.secondary.contrastText,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:disabled': {
        color: palette.button.secondary.disabled,
      },
    },
    error: {
      backgroundColor: palette.button.error.main,
      color: palette.button.error.contrastText,
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: palette.button.error.hover,
        color: palette.button.error.contrastText,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:disabled': {
        color: palette.button.error.disabled,
      },
    },
    underline: {
      backgroundColor: 'transparent',
      color: palette.button.underline.main,
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.button.underline.hover,
      },
      '&:disabled': {
        color: palette.button.underline.disabled,
      },
    },
    refresh: {
      backgroundColor: palette.button.refresh.main,
      color: palette.button.refresh.contrastText,
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: palette.button.refresh.hover,
        color: palette.button.refresh.contrastText,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:disabled': {
        color: palette.button.refresh.disabled,
      },
    },
    disabled: {
      backgroundColor: palette.button.disabled.main,
      color: palette.button.disabled.contrastText,
      boxShadow: 'none',
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      '&:hover': {
        backgroundColor: palette.button.refresh.hover,
        color: palette.button.refresh.contrastText,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:disabled': {
        color: palette.button.refresh.disabled,
      },
    },
    ternary: {
      backgroundColor: palette.button.ternary.main,
      color: palette.button.ternary.contrastText,
      fontSize: 14,
      textTransform: 'capitalize',
      width: 200,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: palette.button.ternary.hover,
        color: palette.button.ternary.contrastText,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:disabled': {
        color: palette.button.ternary.disabled,
      },
    },
  },
  
  fontSize: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 22,
    xxl: 26,
    xxxl: 30,
  },
  colors: {
    grey: palette.grey[500],
    white: palette.common.white,
    success: palette.success.main,
    warning: palette.warning.main,
    error: palette.error.main,
    info: palette.info.main,
    link: palette.link.main,
  },

  Loader: {
    color: palette.primary.dark,
  },
  header: {
    color: palette.common.white,
    backgroundColor: palette.primary.dark,
    fontSize: 14,
    borderRadius: 10,
  },
  search: {
    input: {
      fontSize: 14,
    },
    icon: {
      color: palette.grey[500],
    },
  },
  emptyText: {
    color: palette.grey[500],
    fontSize: 16,
  },
};

let theme = createTheme(_theme);
theme = responsiveFontSizes(theme);

export default theme;
