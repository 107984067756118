import React, { forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import { CircularProgress, styled } from '@mui/material';
import {  Box,  Typography, Theme, Button } from "@mui/material";
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useForm } from "react-hook-form";
import ResumeFormContact from "./ResumeFormContact";
import ResumeFormSkillCategories from "./ResumeFormSkillCategories";
import ResumeFormExperience from "./ResumeFormExperience";
import ResumeFormEducation from "./ResumeFormEducation";
import ResumeFormAward from "./ResumeFormAward";
import ResumeFormProject from "./ResumeFormProject";
import TextDiffComponent from "./TextDiffComponent";
import ResumeFormExperienceGroups from "./ResumeFormExperience";
import { getByPath, getId } from "./utils";
import ResumeFormTestimonials from "./ResumeFormTestimonials";
import ResumeFormReferences from "./ResumeFormReferences";
import ResumeFormPersonalStatements from "./ResumeFormPersonalStatements";


export interface IResumeFormRef {
  submit: () => void; isValid: boolean;
  scrollToElementByPath: (path: string[]) => void;
}

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    '& input, & textarea, .rf-form-input-font': {
      fontSize: '18px',
      fontWeight: 800,
      color: theme.palette.text.secondary,
      fontFamily: "'Consolas', 'Courier New', 'Courier', monospace",
      lineHeight: '1.3rem',
    },
    '.rf-form-input-font': {
      fontSize: '18.1px',
    },
    '& input, & .MuiTextField-root, & textarea, & .MuiChip-root': {
      backgroundColor: 'white'
    },
    [`& .${classes.container}`]: {
      position: "absolute",
      borderRadius: 7,
      width: "50%",
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      margin: theme.spacing(2),
      backgroundColor: theme.colors.white,
    },
  },
}));

interface Props {
  submitText?: string;
  data?: any;
  diffOriginData?: any;
  onDataChange?: (data: any) => void;
  disabled?: boolean;
  ref?: any;
  hideSubmitButton: boolean;
  submitInProgress?: boolean;
  showDiff: boolean;
  showPersonalStatementsSection: boolean;
}

const ResumeForm: React.FC<Props> = forwardRef(({ data, diffOriginData, onDataChange, submitText, disabled, submitInProgress, showDiff, hideSubmitButton, showPersonalStatementsSection }, ref) => {

  const { control, register, handleSubmit, setValue, formState: { isDirty, isValid, errors } } = useForm({});

  // eslint-disable-next-line
  const [formData, setFormData] = React.useState<any>(data);

  const [isSubmitToScrollToError, setIsSubmitToScrollToError] = React.useState<boolean>(false);

  useEffect(() => {
    setFormData(data);
    for (let key in data) {
      setValue(key, data[key])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const _required = true // for quick testing

  const onSubmit = (event?: any) => {
    event.preventDefault();
    if (!isSubmitToScrollToError) {
      handleSubmit((data: any) => {setFormData(data); onDataChange && onDataChange(data); })(event);
    }
    setIsSubmitToScrollToError(false);
  }

  const submitBtnRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      setIsSubmitToScrollToError(false);
      setIsSubmitToScrollToError((value) => {
        submitBtnRef?.current?.click();
        return value;
      })
    },
    scrollToElementByPath: (path: string[]) => {
      const element = document.getElementById(getId(path.join('.')));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.focus();
      }
    },
    
  }));

  return (
      <Root className={classes.root}>

        <form onSubmit={onSubmit}>
          <fieldset disabled={disabled} style={{ border: 'none' }}>

            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (

                <Box>
                  <PrimaryTextInput
                    id={getId(name)}
                    name="name"
                    label="Name &nbsp;&#x2022;&nbsp; Candidate Full name"
                    onChange={onChange}
                    value={value || ''}
                    required={_required && true}
                    multiline={true}
                  />
                </Box>

              )}
              />

            <Controller
              name="summary"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Box>
                  <PrimaryTextInput
                    id={getId(name)}
                    name="summary"
                    label="Summary &nbsp;&#x2022;&nbsp; Candidate Summary statement"
                    onChange={onChange}
                    value={value || ''}
                    required={_required && false}
                    multiline={true}
                  />

                  { showDiff && <TextDiffComponent originalText={diffOriginData?.summary || ''} updatedText={value || ''}
                    onTextUpdated={(newText) => {onChange(newText)}}
                  /> }
                </Box>
              )}
            />

            <ResumeFormContact control={control} diffOriginData={diffOriginData}></ResumeFormContact>

            {/* <Controller
              name="skill_items"
              defaultValue={[]}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <ResumeFormSkillItems control={control} skills={value} onChangeSkills={onChange} />
              )}
            ></Controller> */}

            <Controller
              name="skill_categories"
              defaultValue={{}}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <ResumeFormSkillCategories control={control} skillCategories={value || {}} onChangeSkillCategories={onChange}
                diffOriginData={diffOriginData} showDiff={showDiff} />
              )}
            ></Controller>

            <ResumeFormExperience control={control} fieldPath={'experience'} _required={_required}
              diffOriginData={diffOriginData} showDiff={showDiff} title={'Experience'} />

            {
              control._formValues?.experience_groups &&
              Object.keys(control._formValues?.experience_groups).map((key: string) => {
                const path = 'experience_groups.' + key;
                return <ResumeFormExperience control={control} fieldPath={path} _required={_required}
                  diffOriginData={diffOriginData} showDiff={showDiff} title={key} isOtherExperiance={true}/>
              })
            }

            <ResumeFormEducation control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormEducation>

            <ResumeFormAward control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormAward>

            <ResumeFormProject control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormProject>

            <ResumeFormTestimonials control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormTestimonials>

            <ResumeFormReferences control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormReferences>

            { showPersonalStatementsSection && <ResumeFormPersonalStatements control={control} _required={_required} diffOriginData={diffOriginData} showDiff={showDiff}></ResumeFormPersonalStatements>}

            {/* <p>{formData}</p> */}
            <Box pt={2} pb={1} sx={{visibility: hideSubmitButton ? 'hidden' : 'visible'}}>
              <Button variant="contained" color="primary" type="submit" disabled={disabled} ref={submitBtnRef}>{submitText || 'Save'}</Button>
              <Typography color={'primary'} sx={{
                display: 'inline-block',
                paddingLeft: '16px'}}>
              { submitInProgress && <CircularProgress sx={{
                marginBottom: '-3px', marginRight: '8px',
                width: '20px !important', height: '20px !important'}}/> }
              </Typography>
            </Box>

          </fieldset>
        </form>

      </Root>
  );
});

export default ResumeForm;


