

export function getByPath(obj: any, path: string) {
    var parts = path.split('.');
    for (var i = 0; i < parts.length; i++) {
        if (!obj || !obj.hasOwnProperty(parts[i])) return undefined;
        obj = obj[parts[i]];
    }
    return obj;
}


export const getId = (controlName: string) => {
    return `fm-${controlName}`
}