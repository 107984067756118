import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InfoBox1 = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        maxWidth: '100%',
        minHeight: {
          xs: 'auto', // Adjusted to 'auto' for better responsiveness
          sm: '20vh',
          md: '30vh',
        },

        backgroundColor: theme.palette.background.default ,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 7,

        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        },
        animation: `${fadeInUp} 1s ease forwards`,
      }}
    >
        <Box
            
            sx={{
                maxWidth: '66%',
                minHeight: {
                xs: 'auto', 
                sm: '20vh',
                md: '30vh',
                },
                py:{xs: 2,sm: 4, md:6},
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
        <Typography
            variant={matchesMd ? 'h5' : 'h6'}
            paragraph
            sx={{
            //color:  'grey.700',
            //color: theme.palette.primary.dark,
            // Apply Gradient Background
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            // Clip the background to the text
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text', // For Safari and Chrome
            // Make the text transparent to reveal the gradient
            color: 'transparent',
            WebkitTextFillColor: 'transparent', // For Safari and Chrome
            textAlign: 'center',
            lineHeight: 1.6,
            fontSize: {
                xs: '1.2rem',
                sm: '1.6rem',
                md: '1.8rem',
            },
            fontFamily: 'sans-serif',
            letterSpacing: '1px',
            }}
        >
            Leveraging cutting-edge AI technology, we help you craft a resume that speaks directly to recruiters, passes ATS (Applicant Tracking System) filters, and highlights the skills that matter most.
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoBox1;
