import React from 'react';
import { Typography, Box } from '@mui/material';

const Terms: React.FC = () => {
  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: August 19, 2024
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to ResumeFactoryAI. ResumeFactoryAI is a generative AI tool offered by ObexMetrics Inc. By accessing or using our Service, you agree to be bound by these Terms of Service ("Terms"). Please read them carefully. If you do not agree with any part of these Terms, you may not use the Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Services Provided
      </Typography>
      <Typography variant="body1" paragraph>
        The Service allows users to submit an existing resume and a job description, along with custom instructions, to generate a customized resume tailored to the specified job. The content provided by users is processed using large language models (LLMs) to deliver this service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. User Content and Responsibilities
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Content Submission:</strong> Users may submit their resumes and job descriptions for use in the customization process. It is the user's responsibility to ensure that the information provided is accurate, truthful, and up-to-date. Users are solely responsible for the accuracy of the customized resume generated.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Ownership of Content:</strong> Users retain full ownership of all content submitted through the Service, including resumes and job descriptions. The Service does not claim any rights to your content beyond what is necessary to provide the resume rewriting service.
      </Typography>
      <Typography variant="body2" paragraph>
        The company may transmit information submitted by users to LLM providers, such as OpenAI, Anthropic, and others, in order to leverage the generative AI services they provide.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Prohibited Use:</strong> Users agree not to use the Service to submit content that is illegal, harmful, or violates the rights of others. Users also agree not to use the Service for any unlawful activities.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Payments and Refunds
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Payment Terms:</strong> The Service operates on a pay-per-use model. Payment is required upfront before the resume rewriting service is provided.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>No Refunds:</strong> All payments are non-refundable unless a technical error prevents the Service from functioning as intended. In such cases, please contact us for resolution.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Account Registration
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Optional Registration:</strong> Users may use the Service without creating an account. However, optional registration may be offered to enhance the user experience, such as saving resumes for future use.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Account Security:</strong> If you choose to create an account, you are responsible for maintaining the confidentiality of your login information and for any activities that occur under your account.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate or suspend your access to the Service at our sole discretion, without notice.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Dispute Resolution
      </Typography>
      <Typography variant="body1" paragraph>
        Any disputes arising out of or related to these Terms shall be resolved in accordance with the laws of Delaware, USA. Users agree to submit to the exclusive jurisdiction of the courts located in Delaware, USA.
      </Typography>
      <Typography variant="h6" gutterBottom>
        7. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on the Service. It is your responsibility to review these Terms periodically. Continued use of the Service after any changes indicates your acceptance of the new Terms.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms and any disputes related to them will be governed by the laws of Delaware, USA, without regard to its conflict of law principles. Any dispute arising from these Terms or the use of the Service shall be resolved in the courts located within Delaware, USA, and you consent to the exclusive jurisdiction of such courts.
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        The Service is provided on an "as is" and "as available" basis. ObexMetrics makes no warranties, express or implied, regarding the operation of the Service or the information, content, or materials included therein. To the fullest extent permitted by law, ObexMetrics disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </Typography>
      <Typography variant="h6" gutterBottom>
        10. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by law, ObexMetrics shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        11. Limitation of Scope
      </Typography>
      <Typography variant="body1" paragraph>
        ObexMetrics provides a tool that assists in the customization of resumes based on the information provided by users. ObexMetrics does not guarantee that the resumes created using the Service will result in job offers or interviews. The Service is not responsible for verifying the accuracy or completeness of the content provided by users, and it is the user's responsibility to ensure that all information is accurate and appropriate for the intended job application.
      </Typography>
      <Typography variant="h6" gutterBottom>
        12. Modification of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        ObexMetrics reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on the Service. Your continued use of the Service after any changes indicates your acceptance of the new Terms.
      </Typography>
      <Typography variant="h6" gutterBottom>
        13. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about these Terms, please contact us at:
      </Typography>
      <Typography variant="body2" paragraph>
        Email: contact@obexmetrics.com
      </Typography>
    </Box>
  );
};

export default Terms;
