import React from "react";
import { Box,  Skeleton,Typography } from '@mui/material';


interface Props {
    fileName: string;
    resumeUpdatedAt: string;
}


const ResumeTitle: React.FC<Props> = ({fileName, resumeUpdatedAt}) => {
return (<Box sx={{'display': 'flex', 'align-items': 'center', flexWrap: 'wrap' }}>
        <Typography>{fileName}</Typography>
        {!fileName && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
        <Typography> &nbsp;&#x2022;&nbsp; </Typography>
        {!resumeUpdatedAt && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
        <Typography color="grey">{resumeUpdatedAt}</Typography>
    </Box>)
}

export default ResumeTitle;