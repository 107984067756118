/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Divider, ButtonBase, Paper, ToggleButtonGroup, ToggleButton, alpha, darken, IconButton } from '@mui/material';
// @ts-ignore
import { diff_match_patch } from 'diff-match-patch';
import theme from '../../theme';
import { ContentPaste, MoreVert, MoveUp } from '@mui/icons-material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formDiffGreenColor, formDiffRedColor } from './styles';

interface Props {
    originalText: string;
    updatedText: string;
    onClickOriginal: (text: string) => void;
    onClickUpdated: (text: string) => void;
}

const textBoxStyle = {
  // border: '1px solid #d0d6d6',
  // borderRadius: theme.spacing(0.5),
  padding: '8.5px 14px', // copy from input field styling
  // cursor: 'pointer',
  // marginBottom: 1,
  '& span': { display: 'inline-block' },

  // display: 'flex',
  display: 'block',
  minHeight: '43px',
}

const selectedBgColor = darken(theme.palette.secondary.backgroundLight!, 0.08);

const renderDiffText = (text: string, color: string) => {
  if (text[0] === ' ') {
    text = '&nbsp;' + text.slice(1);
  } else if (text[text.length - 1] === ' ') {
    text = text.slice(0, -1) + '&nbsp;';
  }
  return `<span style="background-color: ${color}; display: inline">${text}</span>`;
}




const TextDiffComponent: React.FC<Props> = ({originalText, updatedText, onClickOriginal, onClickUpdated}) => {
  const [diffOriginal, setDiffOriginal] = useState('');
  const [diffUpdated, setDiffUpdated] = useState('');

  const [option, setOption] = React.useState<number>(0);

  React.useEffect(() => {
    const dmp = new diff_match_patch();
    const diff = dmp.diff_main(originalText, updatedText);
    dmp.diff_cleanupSemantic(diff);

    let originalHtml = '';
    let updatedHtml = '';

    diff.forEach(([op, text]: any) => {
      const escapedText = text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      switch (op) {
        case -1:
          originalHtml += renderDiffText(escapedText, formDiffRedColor);
          break;
        case 1:
          updatedHtml += renderDiffText(escapedText, formDiffGreenColor);
          break;
        case 0:
          originalHtml += escapedText;
          updatedHtml += escapedText;
          break;
      }
    });

    setDiffOriginal(originalHtml);
    setDiffUpdated(updatedHtml);

    if (option === 1 && diffUpdated !== updatedHtml) {
      setOption(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalText, updatedText]);


  const selectedStyle = {  
    backgroundColor: selectedBgColor,
    '& > .MuiTypography-body2': {
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
    }
  }


  return (
    <Box sx={{ width: '100%', margin: 'auto', pt: 2, pb: 4 }}>

      <Box onClick={() => {onClickUpdated(originalText); setOption(0)}}
        sx={{...(option === 0 ? selectedStyle : {}), pb: 1, borderTopLeftRadius: 8, borderTopRightRadius: 8, border: '1px solid rgba(0, 0, 0, 0.23)', cursor: 'pointer', position: 'relative' }}>


        <span style={{ position: 'absolute', top: '-15px', left: theme.spacing(1), zIndex: 1,
            backgroundColor: option === 0 ? selectedBgColor: theme.palette.secondary.backgroundLight,
            borderTopRightRadius: theme.spacing(0.5),
            borderTopLeftRadius: theme.spacing(0.5),
            paddingLeft: '5px',
            paddingRight: '6px',
            border: '1px solid #b9c6c6',
            borderBottom: 'none',
            height: '15px',
          }}>
  
          <Typography variant="body2" component="span" gutterBottom sx={{ fontSize: '12px', display: 'block' }}>
            Proposed
          </Typography>
        </span>

        <Box sx={textBoxStyle}>
          {diffUpdated ? <Box
            className="rf-form-input-font"
            dangerouslySetInnerHTML={{ __html: diffUpdated }}
          /> : <Typography variant="body2" component="span" sx={{fontStyle: 'italic', color: 'grey'}}>Empty</Typography>}
        </Box>
      </Box>

      <Box onClick={() => {onClickOriginal(updatedText); setOption(1)}}
        sx={{ ...(option === 1 ? selectedStyle : {}), borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: '1px solid rgba(0, 0, 0, 0.23)', cursor: 'pointer', borderTop: 'none', position: 'relative' }}>
        
        <span style={{ position: 'absolute', top: '-15px', left: theme.spacing(1), zIndex: 1,
            backgroundColor: option === 1 ? selectedBgColor: theme.palette.secondary.backgroundLight,
            borderTopRightRadius: theme.spacing(0.5),
            borderTopLeftRadius: theme.spacing(0.5),
            paddingLeft: '5px',
            paddingRight: '6px',
            border: '1px solid #b9c6c6',
            borderBottom: 'none',
            height: '15px',
          }}>
  
          <Typography variant="body2" component="span" gutterBottom sx={{ fontSize: '12px', display: 'block' }}>
            Original
          </Typography>
        </span>

        <Box sx={textBoxStyle}>
          {diffOriginal ? <Box
            className="rf-form-input-font"
            dangerouslySetInnerHTML={{ __html: diffOriginal }}
          /> : <Typography variant="body2" component="span" sx={{fontStyle: 'italic', color: 'grey'}}>Empty</Typography>}
        </Box>
      </Box>

    </Box>
  );
};


interface TextDiffComponentWrapperProps {
  originalText: string;
  updatedText: string;
  onTextUpdated: (text: string) => void;
}

const TextDiffComponentWrapper: React.FC<TextDiffComponentWrapperProps> = ({originalText, updatedText, onTextUpdated}) => {
  
  const [updatedTextState, setUpdatedTextState] = useState(updatedText);
  const [updatedTextMemoryState, setUpdatedTextMemoryState] = useState<string|undefined>(undefined);

  const [showTheNoDiff, setShowTheNoDiff] = useState(false);
  const [hasDiff, setHasDiff] = useState(true);

  useEffect(() => {
    if (updatedText === originalText && updatedTextMemoryState !== undefined) {
      setUpdatedTextState(updatedTextMemoryState);
      setUpdatedTextMemoryState(undefined);
      
    } else {
      setUpdatedTextState(updatedText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalText, updatedText]);

  useEffect(() => {
    setHasDiff((originalText !== updatedTextState) || !!updatedTextMemoryState);
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalText, updatedText, updatedTextState, updatedTextMemoryState]);

  const onClickOriginal = (text: string) => {
    setUpdatedTextMemoryState(updatedText);
    onTextUpdated(originalText);
  }

  const onClickUpdated = (text: string) => {
    onTextUpdated(updatedTextState);
  }

  return (
    <Box sx={{pb: !((!hasDiff && showTheNoDiff) || hasDiff) ? 1 : 0 }}>
    {!hasDiff && <Typography component='span' onClick={() => setShowTheNoDiff(!showTheNoDiff)} sx={{fontSize: '12px', cursor: 'pointer'}}>
      No diff <IconButton>{showTheNoDiff ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/> }</IconButton>
    </Typography>}
    {((!hasDiff && showTheNoDiff) || hasDiff) ?
    <TextDiffComponent originalText={originalText} updatedText={updatedTextState} onClickOriginal={onClickOriginal} onClickUpdated={onClickUpdated}/> : ''}
    </Box>
  );
};

export default TextDiffComponentWrapper;
