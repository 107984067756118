import { Reducer, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UserReducer from './User/Reducer';
import AlertReducer from './Alert/Reducer';
import { CLEAR_PERSIST_ROOT } from './Root/Types';
import userBalanceReducer from './UserBalance/Reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'] //The reducers should persist
}

const appReducer = combineReducers({
    user: UserReducer,
    userBalance: userBalanceReducer,
    alert: AlertReducer,
});
const debugRedux = false;
const rootReducer: Reducer = (state: any, action: any) => {
    if (debugRedux) {
      console.log(action.type, 'action', action);
      console.log(action.type, 'state', state);
    }
    if (action.type === CLEAR_PERSIST_ROOT) {
      // this applies to all keys defined in persistConfig(s)
      storage.removeItem('persist:root')
      state = {}
    }
    const newState = appReducer(state, action);
    if (debugRedux) {
      console.log(action.type, 'new state', newState);
    }
    return newState;
  }

export default persistReducer(persistConfig, rootReducer);