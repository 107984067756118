
export const USER_BALANCE_LOADING = 'USER_BALANCE_LOADING';
export const USER_BALANCE_LOADING_FAILED = 'USER_BALANCE_LOADING_FAILED';
export const USER_BALANCE_SAVE = 'USER_BALANCE_SAVE';
export const USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS = 'USER_BALANCE_UPGRADE_MEMBERSHIP_IN_PROGRESS';
export const USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED = 'USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED';



export const enum MembershipType {
    COMMUNITY = 'COMMUNITY',
    PRO = 'PRO',
    ENTERPRISE = 'ENTERPRISE',
}
  