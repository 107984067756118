import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, LinearProgress, Paper, Skeleton, Tab, Tabs, alpha, styled } from '@mui/material';
import { Box, IconButton, Typography, Theme } from "@mui/material";
import {
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';


import { Comparison } from "../Services/Http/interface";
import theme from "../theme";
import GaugeComponent from "react-gauge-component";
import Loading from "./Loading";

const PREFIX = 'ComparisonScoresViewModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {    
    // Add styles if needed
  },

  '& .main-accordion > .MuiCollapse-root': {
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
      height: '100% !important',
    },
  },


  '& .main-accordion > div > .MuiAccordionSummary-expandIconWrapper': {
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.palette.common.white, // Fixed theme color
  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

// const ScoreSectionOriginal = ({ title, score, reasoning } : {title: string, score: number, reasoning: string}) => {
//   return (
//     <Box mb={2}>
//       <Typography variant="h6" gutterBottom>
//         {title}
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Score: {score}
//       </Typography>
//       <Typography variant="body2" color="textSecondary">
//         {reasoning}
//       </Typography>
//     </Box>
//   );
// };
// Commented out because 'ScoreSectionOriginal' is assigned but never used
// const ScoreSectionOriginal = ({ title, score, reasoning } : {title: string, score: number, reasoning: string}) => { ... }

const CollapsibleScoreComponent = ({ title, score, reasoning }: {
  title: string, score: number, reasoning: string}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" alignItems="center" width="100%">
          <Box flexGrow={1}>
            <Typography>{title}</Typography>
          </Box>
          <Box display="flex" alignItems="center" width="180px">
            <LinearProgress 
              variant="determinate" 
              value={score} 
              sx={{ flexGrow: 1, mr: 1 }}
            />
            <Typography variant="body2">{score}%</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{pt: 0}}>
        <Typography variant="body2" color="textSecondary">
          <span style={{ fontWeight: 'bold' }}>{reasoning}</span>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const ScoreSection = CollapsibleScoreComponent;


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




interface Props {
  tailoredComp?: Comparison;
  unTailoredComp?: Comparison;
  isLoading: boolean;
  onClose?: () => void;
}

const ComparisonScoresTabView: React.FC<Props> = ({ tailoredComp, unTailoredComp, isLoading, onClose }) => {

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
      <Root sx={{ width: '100%' }}>
        {!tailoredComp && isLoading &&
        <Box sx={{ width: '100%' }}>
          <Typography variant='body2' p={1} sx={{'& > div': { display: 'inline !important', paddingLeft: '8px' }}}>Loading resume relevancy scores
            <Loading type='bubble' open={true} size={6}/>
            {/* <Loading type='circular' open={true} size={16}/> */}
          </Typography>
          <Box p={1}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </Box>

          <Box sx={{ display: 'flex', justifyContent:'center' }}>
            <Skeleton variant="circular" width={56} height={56} />
          </Box>

          <Box p={1}>
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          </Box>
          <Box p={1} pt={0}>
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          </Box>
          <Box p={1} pt={0}>
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          </Box>
          <Box p={1} pt={0}>
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          </Box>
          <Box p={1} pt={0}>
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          </Box>
        </Box>}

        {tailoredComp && unTailoredComp && !isLoading && 

        <Box>     
          <Grid item >

            {/* <Box>
              <IconButton onClick={() => onClose && onClose() } sx={{ display: {xs: 'none', sm: 'none', md: 'inline-flex'} }}>
                <ChevronRight />
              </IconButton>
              <IconButton onClick={() => onClose && onClose() } sx={{ display: {xs: 'none', sm: 'none', md: 'inline-flex'} }}>
                <ChevronRight />
              </IconButton>
            </Box> */}

            <Tabs 
              value={value} 
              onChange={handleChange}
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              <Tab 
                sx={{
                  minWidth: '50%',
                  '&.Mui-selected': {
                    background: 'white',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    zIndex: 1,
                    boxShadow: 'none',
                  },
                  borderRadius: '8px 8px 0 0',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderBottom: 'none',
                  marginRight: 1,
                  '&:not(.Mui-selected)': {
                    backgroundColor: alpha(theme.palette.secondary.light, 0.15),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
                  }
                }}
                label={getScoreBarView(unTailoredComp, 'Original Score')} 
                {...a11yProps(0)} 
              />
              <Tab 
                sx={{
                  minWidth: '50%',
                  '&.Mui-selected': {
                    background: 'white',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    zIndex: 1,
                    boxShadow: 'none',
                  },
                  borderRadius: '8px 8px 0 0',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderBottom: 'none',
                  '&:not(.Mui-selected)': {
                    backgroundColor: alpha(theme.palette.secondary.light, 0.15),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
                  }
                }}
                label={getScoreBarView(tailoredComp, 'Tailored Score')} 
                {...a11yProps(1)} 
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <SingleComparisonScoresView data={unTailoredComp} isLoading={isLoading} expanded={expanded} setExpanded={setExpanded} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SingleComparisonScoresView data={tailoredComp} isLoading={isLoading} expanded={expanded} setExpanded={setExpanded} />
            </CustomTabPanel>

          </Grid>
        </Box>
        }

      </Root>
  );
};

export default ComparisonScoresTabView;


interface ComparisonScoresSideBySideViewProps {
  tailoredComp?: Comparison;
  unTailoredComp?: Comparison;
  isLoading: boolean;
  onClose?: () => void;
}

export const ComparisonScoresSideBySideView: React.FC<ComparisonScoresSideBySideViewProps> = ({ tailoredComp, unTailoredComp, isLoading, onClose }) => {

  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
      <Root sx={{ width: '100%' }}>

        {tailoredComp && unTailoredComp && !isLoading && 

        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

          <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
            {getScoreBarView(unTailoredComp, 'Original Resume Score', true)}
            
            <Box>
              <Box pl={2} pb={2} pt={2} pr={1}>
                <Typography variant="body2" color="textSecondary">
                  <span style={{ fontWeight: 'bold' }}>{unTailoredComp?.overall_score_reasoning}</span>
                </Typography>
              </Box>

              {getComparisonReasoningView(unTailoredComp)}
            </Box>
          </Paper>

          <Box p={1}></Box>

          <Box>
            <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
              {getScoreBarView(tailoredComp, 'Tailored Resume Score', true)}
              
              <Box>
                <Box pl={2} pb={2} pt={2} pr={1}>
                  <Typography variant="body2" color="textSecondary">
                    <span style={{ fontWeight: 'bold' }}>{tailoredComp?.overall_score_reasoning}</span>
                  </Typography>
                </Box>

                {getComparisonReasoningView(tailoredComp)}
              </Box>
            </Paper>
          </Box>
            

        </Box>
        }

      </Root>
  );
};


const getScoreBarView = (data: any, title: string, isBigView: boolean = false) => {

  const smallBarSx = {
    width: '240px',
    marginTop: theme.spacing(-7),
    height: '98px',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    marginLeft: '-53px',
    marginRight: '-44px',
  }

  const bigBarSx = {
    width: '340px',
    marginTop: theme.spacing(-6),
    height: '156px',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  }

  return <Box sx={{}}>
    <Box pl={2} sx={{}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}} pb={1}>
          <Box>
          <Typography variant={ isBigView ? 'h6' : 'body1' } gutterBottom pt={1}>
            {title}
          </Typography>
          {/* <Typography variant="body1" gutterBottom>score</Typography> */}
          </Box>
      </Box>

    <Box sx={ isBigView ? bigBarSx : smallBarSx }>
      <GaugeComponent
        value={data?.overall_score}
        arc={{
          emptyColor: alpha(theme.palette.primary.main, 0.3),
          colorArray: [theme.palette.error.light, theme.palette.secondary.dark],
          padding: 0.02,
          subArcs:
            [
              { limit: 40, showTick: false },
              { limit: 60, showTick: false },
              { limit: 70, showTick: false },
              {},
              {},
              {},
              {}
            ]
          
        }}
        labels={{  
          valueLabel: {
            style: {
              fill: theme.palette.text.primary,
              fontWeight: 'bold',
              textShadow: 'none',
            },
          },
          tickLabels: {
            hideMinMax: true,
          }
        }}
      />
    </Box>
  </Box>
  </Box>
}


interface SingleViewProps {
  data: Comparison,
  isLoading: boolean;
  onClose?: () => void,
  expanded: boolean,
  setExpanded: (expanded: boolean) => void
}

const SingleComparisonScoresView: React.FC<SingleViewProps> = ({ data, isLoading, onClose, expanded, setExpanded }) => {

  return (<Box>
    <Accordion expanded={expanded} onChange={(event, expanded) => setExpanded(expanded)} className="main-accordion">
      
      <AccordionSummary expandIcon={<ExpandMore />}>
          <Box sx={{}}>
            <Box pl={2} pb={1} pt={2} pr={1}>
              <Typography variant="body2" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>{data?.overall_score_reasoning}</span>
              </Typography>
            </Box>
          </Box>
      </AccordionSummary>
        {getComparisonReasoningView(data)}
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
  </Box>);
}


const getComparisonReasoningView = (data: Comparison) => {
  const _md = 3;

  return <>
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Hard Skills"
        score={data.hard_skills_score}
        reasoning={data.hard_skills_score_reasoning}
      />
    </Grid>
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Soft Skills"
        score={data.soft_skills_score}
        reasoning={data.soft_skills_score_reasoning}
      />
    </Grid>
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Work Experience"
        score={data.work_experience_score}
        reasoning={data.work_experience_score_reasoning}
      />
    </Grid>
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Education"
        score={data.education_score}
        reasoning={data.education_score_reasoning}
      />
    </Grid>
    {data.certifications_score !== null && (
      <Grid item xs={12} md={_md}>
        <ScoreSection
          title="Certifications"
          score={data.certifications_score}
          reasoning={data.certifications_score_reasoning}
        />
      </Grid>
    )}
    {data.languages_score !== null && (
      <Grid item xs={12} md={_md}>
        <ScoreSection
          title="Languages"
          score={data.languages_score}
          reasoning={data.languages_score_reasoning}
        />
      </Grid>
    )}
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Projects"
        score={data.projects_score}
        reasoning={data.projects_score_reasoning}
      />
    </Grid>
    <Grid item xs={12} md={_md}>
      <ScoreSection
        title="Awards"
        score={data.awards_score}
        reasoning={data.awards_score_reasoning}
      />
    </Grid>
  </>
}
