import {
    USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED,
    USER_BALANCE_LOADING,
    USER_BALANCE_LOADING_FAILED,
    USER_BALANCE_SAVE,
} from './Types';

interface State {
    isLoading: boolean,
    isLoadingFailed: boolean,
    balanceObexcoinsAmount: number,
    membershipType: string,
    membershipActiveUntil: string,
    membershipTypeText: string,
    checkoutSessionSecret?: string,
    proMembershipDailyCost: number | undefined,
    stripePublicKey: string,
}

interface Action {
    type: string,
    payload: any
}

const initialState: State = {
    isLoading: false,
    isLoadingFailed: false,
    balanceObexcoinsAmount: 0,
    membershipType: '',
    membershipActiveUntil: '',
    membershipTypeText: '',
    checkoutSessionSecret: '',
    proMembershipDailyCost: undefined,
    stripePublicKey: '',
}

const membershipTypeToTextMap: {[key: string]: string} = {
    'COMMUNITY': 'Community',
    'PRO': 'Pro',
    'ENTERPRISE': 'Enterprise',
}

const userBalanceReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case USER_BALANCE_LOADING: return {
            ...state,
            isLoading: action.payload,
        }
        case USER_BALANCE_LOADING_FAILED: return {
            ...state,
            isLoadingFailed: true,
        }
        case USER_BALANCE_SAVE: return {
            ...state,
            ...action.payload,
            membershipTypeText: membershipTypeToTextMap[action.payload?.membershipType],
        }
        case USER_BALANCE_CHECKOUT_SESSION_SECRET_RECEIVED: return {
            ...state,
            checkoutSessionSecret: action.payload.checkoutSessionSecret,
        }
        default: return state
    }
}

export default userBalanceReducer;