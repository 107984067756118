import { ALERT_SHOW, ALERT_HIDE } from './Types';

interface Props {
    open: boolean,
    type: 'error' | 'info' | 'warning' | 'success',
    message: string,
    duration: number
}

interface Action {
    type: string,
    payload: any
}

const initialState: Props = {
    open: false,
    type: 'success',
    message: '',
    duration: 3000
}



const Reducer = (state = initialState, action: Action): any => {
    switch (action.type) {
        case ALERT_SHOW: return {
            ...state,
            ...action.payload,
            open: true
        }
        case ALERT_HIDE: return {
            ...state,
            ...initialState,
        }
        default: return state
    }
}

export default Reducer;