import React from 'react';
import { Typography, Box} from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: August 19, 2024
      </Typography>
      <Typography variant="body1" paragraph>
        ResumeFactoryAI is a generative AI tool offered by ObexMetrics Inc. ObexMetrics Inc ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access or use the Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Personal Information:</strong> When you use our Service, we may collect personal information such as your name, email address, and payment information. We may also collect any personal information included in the resumes and job descriptions you submit.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Usage Data:</strong> We use Google Analytics to help us understand how visitors engage with our site. Google Analytics collects standard internet log information and details about visitor behavior in a way that doesn't directly identify anyone. This includes:
        <ul>
          <li>Usage patterns (pages visited, time spent on site, interactions)</li>
          <li>Device information (browser type, operating system, screen resolution)</li>
          <li>Approximate location (country/city level only)</li>
          <li>IP address (in anonymized form)</li>
          <li>Cookies and similar technologies used for analytics purposes</li>
        </ul>
        For more information about how Google processes this data, please visit <a href="https://www.google.com/policies/privacy/partners/">How Google uses information from sites or apps that use our services</a>. You can opt-out of Google Analytics tracking by:
        <ul>
          <li>Installing the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a></li>
          <li>Using your browser's Do Not Track feature</li>
        </ul>
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Cookies and Similar Technologies:</strong> We use cookies and similar tracking technologies to collect and track information about your usage of our Service. Cookies are small text files that websites place on your device. We use:
        <ul>
          <li>Analytics cookies (via Google Analytics) to understand how visitors use our site</li>
          <li>Essential cookies necessary for the Service to function</li>
        </ul>
        Most web browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may impact your overall user experience.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Service Delivery:</strong> We use the information you provide to deliver and customize the resume rewriting service. This includes processing the resumes and job descriptions you submit to generate customized resumes tailored to your specifications.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Improving the Service:</strong> We may use aggregated and anonymized data to analyze trends, monitor the effectiveness of our Service, and improve its functionality.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Communication:</strong> We may use your contact information to send you updates, notifications, and other communications related to the Service. You can opt-out of non-essential communications at any time.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Legal Compliance:</strong> We may use your information as required by applicable laws and regulations, including to comply with legal processes or enforce our Terms of Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers who assist us in operating the Service. This includes:
        <ul>
          <li>Google Analytics (as a data processor) for website analytics and usage tracking</li>
          <li>Payment processors for handling transactions</li>
          <li>Cloud service providers for hosting and storage</li>
          <li>OpenAI and Anthropic for AI processing</li>
        </ul>
        These providers are required to protect your information and use it only for the specific purposes authorized by us. Google Analytics data may be processed in various countries, including the United States, in accordance with Google's data processing terms.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Data Processing
      </Typography>
      <Typography variant="body1" paragraph>
        For Google Analytics, Google acts as a data processor and ObexMetrics Inc acts as the data controller. Google processes this data in accordance with its Data Processing Terms, implementing appropriate security measures and processing data only according to our instructions. Data collected through Google Analytics may be transferred to, stored, and processed in the United States or other countries where Google maintains facilities.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Security of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Data Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including to comply with legal obligations, resolve disputes, and enforce our agreements.
      </Typography>
      <Typography variant="h6" gutterBottom>
        7. Your Rights and Choices
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Access and Correction:</strong> You have the right to access the personal information we hold about you and to request corrections or updates. You may also request the deletion of your personal information, subject to certain legal exceptions.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Opt-Out:</strong> You may opt-out of receiving non-essential communications from us by following the unsubscribe instructions provided in the emails. Please note that you may still receive administrative and service-related communications.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. International Data Transfers
      </Typography>
      <Typography variant="body1" paragraph>
        If you are accessing our Service from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States or other countries where our service providers are located. By using the Service, you consent to such transfers.
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Children's Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our Service is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected personal information from a child under 18, we will take steps to delete such information.
      </Typography>
      <Typography variant="h6" gutterBottom>
        10. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Service. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </Typography>
      <Typography variant="h6" gutterBottom>
        11. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </Typography>
      <Typography variant="body2" paragraph>
        Email: contact@obexmetrics.com
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
