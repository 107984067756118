import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import RootReducer from './RootReducer';
import { createRouterMiddleware, createRouterReducerMapObject } from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// Static reducers that will always be included
const staticReducers = {
    host: RootReducer,
    ...createRouterReducerMapObject(history), // This adds the router-related reducers
};

function createStore() {
    // Create the Redux store with router middleware and any other middlewares
    const store = configureStore({
        reducer: staticReducers,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types to avoid warnings about non-serializable values
                    ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                    // Optionally, you can ignore specific paths in the state that are non-serializable
                    ignoredPaths: ['register'],
                },
            }).concat(createRouterMiddleware(history)),
    });

    return store;
}

const store = createStore();
const persistor = persistStore(store);

export {
    store,
    persistor,
    history,
};

