import React, { useEffect, useState } from 'react';
import { TextField, ClickAwayListener, Typography, TypographyProps } from '@mui/material';

interface Props {
    label: string;
    onLabelChange?: (label: string) => void;
    onDelete?: () => void;
    showAsDeleted?: boolean;
    onUndoDelete?: () => void;
    // ...props;
}

const EditableTypography: React.FC<TypographyProps & Props> = ({ label, onLabelChange, onDelete, showAsDeleted, onUndoDelete, ...props }) => {
  const [isEditing, setIsEditing] = useState(!label);

  const [labelValue, setLabelValue] = useState(label);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event: any) => {
    setLabelValue(event.target.value);
  };

  const handleClickAway = () => {
    setIsEditing(false);
    if (labelValue !== label && onLabelChange) {
        onLabelChange(labelValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onLabelChange && onLabelChange(labelValue);
    }
  };

  useEffect(() => {
    if (!isEditing && labelValue === '') {
      onDelete && onDelete();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);



  return (
    showAsDeleted ? (
      <Typography onClick={() => onUndoDelete && onUndoDelete()} variant="body2" component="span"
        style={{ margin: '0 8px', display: 'inline-block', textDecoration: 'line-through', backgroundColor: '#ffcccb', width: 'fit-content' }}
        {...props}>{label}
        {props.children}
      </Typography>
    ) : (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {isEditing ? (
          <TextField
            value={labelValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            size="small"
            style={{ width: '100%', minWidth: '225px' }}
            inputProps={{ style: { padding: '4.5px 14px' }}}
            // multiline={true} causes a bug, multiline input falls behind the other draggable elements
          />
        ) : (
          <Typography onDoubleClick={handleDoubleClick} variant="body2" component="span" style={{ margin: '0 8px', display: 'inline-block' }} {...props}>{label}
          {props.children}
          </Typography>
        )}
      </div>
    </ClickAwayListener>
  ));
};

export default EditableTypography;
