import { useSelector } from 'react-redux';
import { selectUser } from '../Redux';

type Role = 'USER' | 'ADMIN' | 'SUPER_ADMIN'

interface Credentials {
    provider: string,
    roleArn: string,
    region: string
}

interface User {
    id: string,
    apiUserId: string,
    name: string,
    email: string,
    role: Role
    accessToken: string,
    impersonatedId: string,
    credentials: Credentials[],
    isAdmin: boolean
}

const useUser = (): any => {
    const {
        id,
        apiUserId,
        name,
        email,
        role,
        accessToken,
        impersonatedId,
        credentials,
        isAdmin
    }: User = useSelector(selectUser);



    return {
        originalUserId: id,
        userId: apiUserId,
        name,
        email,
        role,
        accessToken,
        impersonatedId,
        credentials,
        isAdmin
    }
}
export default useUser;