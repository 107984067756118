import { ALERT_SHOW, ALERT_HIDE } from './Types';

export const onAlertShowAction = ({ type, message, duration, keep }: { type?: string, message: string, duration?: number, keep?: boolean }): any => {
    return (dispatch: any) => {
        let payload = { type, message, duration: duration };
        /* Set default error message */
        if (type === 'error' && !message) {
            payload = { ...payload, message: 'Something went wrong!' }
        }
        if (!duration && !keep) {
            payload = { ...payload, duration: 3000 }
        }
        dispatch({ type: ALERT_SHOW, payload: payload });
    }
}

export const onAlertCloseAction = (): any => {
    return (dispatch: any) => {
        dispatch({ type: ALERT_HIDE });
    }
}