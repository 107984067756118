import React from "react";
import { styled } from '@mui/material/styles';

import { TextField, Theme } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField"; 
import  theme from '../theme';

const PREFIX = 'PrimaryTextInput';

const classes = {
  primaryTextInput: `${PREFIX}-primaryTextInput`,
  resize: `${PREFIX}-resize`
};

const StyledTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.primaryTextInput}`]: {
    fontSize: 10,
  },

  [`& .${classes.resize}`]: {
    fontSize: 10,
  }
}));

type Variant = "filled" | "outlined" | "standard";
type type = "text" | "password";

interface Props {
  variant?: Variant;
  fullWidth?: boolean;
  required: boolean;
  label: string;
  name: string;
  type?: type;
  autoFocus?: boolean;
  value: any;
  onChange: (value: any) => void;
  error?: boolean;
  helperText?: string;
  InputProps?: Partial<TextFieldProps['InputProps']>;
  autocomplete?: string;
}

const PrimaryTextInput: React.FC<any & Props> = ({
  variant,
  fullWidth,
  required,
  label,
  name,
  type,
  autoFocus,
  value,
  onChange,
  error,
  helperText,
  InputProps,
  autocomplete,
  ...props
}) => {

  return (
    <StyledTextField
      size="small"
      className={classes.primaryTextInput}
      variant={variant || "outlined"}
      fullWidth={fullWidth || true}
      margin="normal"
      required={required}
      id={name}
      label={label}
      name={name}
      autoComplete={autocomplete || name} 
      type={type || "text"}
      autoFocus={autoFocus}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      helperText={helperText || ""}
      InputProps={{
        ...InputProps, 
        style: { fontSize: theme.textInput.fontSize, ...(InputProps?.style || {}) }
      }}
      InputLabelProps={{ style: { fontSize: theme.textInput.fontSize } }}
      {...props}
    />
  );
};

export default PrimaryTextInput;
