// src/components/Layout.js
import React, { useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './AppBar';
import { Box, Container, Typography, Link as MuiLink, Breakpoint } from '@mui/material';
import theme from '../theme';
import { trackPageView } from '../utils/analytics';

function Copyright() {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body2" color="textSecondary" paragraph>
        This site uses cookies and Google Analytics to analyze traffic. Analytics respects Do Not Track.{' '}
        <MuiLink component={Link} to="/privacy" color="inherit">
          Learn more
        </MuiLink>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {"© "}
        <MuiLink color="inherit" href="https://ResumeFactory.ai/">
          ResumeFactory.ai
        </MuiLink>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
}

const Layout: React.FC<{ user: any, containerDisableGutters?: boolean, containerMaxWidth?: Breakpoint|boolean }> = ({ user, containerDisableGutters, containerMaxWidth }) => {


  containerDisableGutters = containerDisableGutters || false;
  if (!containerMaxWidth && containerMaxWidth !== false) {
    containerMaxWidth = containerMaxWidth || 'xl';
  }

  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <Box 
        sx={{ minHeight: '100vh', 
              width: '100%',
              display: "flex", 
              flexDirection: "column",
              //backgroundColor: theme.palette.common.white
              //background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.secondary.light} 90%)`,
              
              }}>
      <ResponsiveAppBar user={user} containerMaxWidth={containerMaxWidth} containerDisableGutters={containerDisableGutters}/>

      {/* @ts-ignore */}
      <Container component="main" maxWidth={containerMaxWidth} disableGutters={containerDisableGutters}>
        <Outlet />
      </Container>
      
      <Box
        component="footer"
        sx={{
          marginTop: 'auto',
          py: 3,
          px: 2,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Container 
          maxWidth={containerMaxWidth as Breakpoint | false} 
          disableGutters={containerDisableGutters}
        >
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center'
              }}
            >
              <MuiLink component={Link} to="/privacy" variant="body2">
                Privacy Policy
              </MuiLink>
              <MuiLink component={Link} to="/terms" variant="body2">
                Terms of Service
              </MuiLink>
            </Box>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;