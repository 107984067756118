// HeroSection.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery, Fade,  Grid, TextField, Icon, Slide, Zoom} from '@mui/material';
// import  ImgProfessionalWebp  from '../Assets/ImgProfessionalWebp.webp';
import ImgSparks from '../Assets/sparks-large.png';
import { CheckCircleOutline } from '@mui/icons-material';
import { httpOnJoinWaitlist } from '../Services/Http';

// Define the shape of the props
interface HeroSectionProps {
  handleGetStartedClick: () => void;
  buttonText: string;
  isWaitlistMode: boolean;
}


const HeroSection: React.FC<HeroSectionProps> = ({ handleGetStartedClick, buttonText, isWaitlistMode }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Animation states
  const [showImage, setShowImage] = useState(false);
  const [showMainHeading, setShowMainHeading] = useState(false);
  const [showSubHeading, setShowSubHeading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const [waitlistEmail, setWaitlistEmail] = useState('');
  const [waitlisted, setWaitlisted] = useState(false);

  const [waitlistEmailRequiredError, setWaitlistEmailRequiredError] = useState('');
  const [waitlistStatusMessage, setWaitlistStatusMessage] = useState('');
  const [waitlistInProgress, setWaitlistInProgress] = useState(false);

  useEffect(() => {
    // Sequentially show elements with delays
    setShowImage(true);
    setTimeout(() => {
      setShowMainHeading(true);
    }, 500);
    setTimeout(() => {
      setShowSubHeading(true);
    }, 1000);
    setTimeout(() => {
      setShowButton(true);
    }, 1500);
  }, []);

  const btnSx = {
    ...theme.button.primary,
    mt: {
      xs: 3,
      sm: 4,
      md: 5,
    },
    px: {
      xs: 4,
      sm: 5,
      md: 6,
    },
    py: {
      xs: 1.2,
      sm: 1.5,
      md: 1.8,
    },
    fontSize: {
      xs: '0.9rem',
      sm: '1rem',
      md: '1.1rem',
    },
    width: {
      xs: '80%',
      sm: 'auto',
    },
  }

  function isValidEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    if (waitlistEmailRequiredError) {
      if (waitlistEmail.length > 0 && isValidEmail(waitlistEmail)) {
        setWaitlistEmailRequiredError('');
      }
    }
  }, [waitlistEmailRequiredError, waitlistEmail]);


  const handleJoinToWaitlistClick = () => {

    if (!waitlistEmail) {
      setWaitlistEmailRequiredError('Please provide your email address');
      return;
    }
    if (!isValidEmail(waitlistEmail)) {
      setWaitlistEmailRequiredError('Please provide valid email');
      return;
    }
    setWaitlistEmailRequiredError('');

    setWaitlistInProgress(true);

    httpOnJoinWaitlist(waitlistEmail).then((res: any) => {
      setWaitlistInProgress(false)

      setWaitlisted(true);

      if (res?.statusCode === 201) {
        setWaitlistStatusMessage("Thank you for your interest! We will notify you when ResumeFactory.ai is ready to use.")
      } else {
        setWaitlistStatusMessage("Thank you for your interest! Your email is already in our waitlist. We will notify you when ResumeFactory.ai is ready to use.")
      }

    }).catch((e) => {
      setWaitlistInProgress(false)
      setWaitlistStatusMessage("Sorry, something went wrong please try again.")
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: {
          xs: '20vh',
          sm: '30vh',
          md: '50vh',
          lg: '70vh',
        },
        pt: {
          xs: 0,
          sm: 2,
          md: 3,
        },
        pb: {
          xs: 1,
          sm: 2,
          md: 3,
        },
        background: `linear-gradient(180deg, 
                   ${theme.palette.primary.light} 0%, 
                   ${theme.palette.background.default} 100%)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        '@media (orientation: landscape) and (max-width:600px)': {
          minHeight: '50vh',
          pb: 4,
        },
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={'auto'} sx={{justifyContent: 'center', display: 'flex' }}>
          <Fade in={showImage} timeout={1000}>
            <Box
              component="img"
              src={ImgSparks}
              alt="AI-powered Resume Transformation"
              sx={{
                width: '100%',
                maxWidth: '600px', // Increased from 550px to 600px
                height: 'auto',
                filter: 'brightness(0.8)',
                opacity: 0.8,
                borderRadius: { xs: 0, sm: 1},
                // pl: { xs: 0, md: 4 }, // Add left padding for medium screens and up
              }}
            />
          </Fade>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Fade in={showMainHeading} timeout={1000}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 900,
                  fontSize: {
                    xs: '2.3rem',
                    sm: '3.0rem',
                    md: '3.5rem',
                    lg: '4.0rem',
                    xl: '4.5rem',
                  },
                  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'Roboto',
                }}
              >
                Transform Your Resume with AI
              </Typography>
            </Fade>

            <Fade in={showMainHeading} timeout={1000}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 900,
                  fontSize: {
                    xs: '1.8rem',
                    sm: '2.5rem',
                    md: '3rem',
                    lg: '3.5rem',
                    xl: '4rem',
                  },
                  color: 'grey.800',
                  fontFamily: 'sans-serif',
                  mb: {
                    xs: 5,
                    sm: 7,
                    md: 9,
                  },
                }}
              >
                Land Your Dream Job
              </Typography>
            </Fade>

            <Fade in={showSubHeading} timeout={1000}>
              <Typography
                variant="h6"
                color="textPrimary"
                gutterBottom
                sx={{
                  fontWeight: 400,
                  maxWidth: '100%',
                  mb: {
                    xs: 3,
                    sm: 4,
                    md: 5,
                  },
                  fontSize: {
                    xs: '1rem',
                    sm: '1.1rem',
                    md: '1.2rem',
                    lg: '1.3rem',
                  },
                }}
              >
                Stand out from the crowd with a resume crafted by ResumeFactory.ai's advanced AI technology.
              </Typography>
            </Fade>

            
            <Fade in={showButton} timeout={1000}>
            {
              isWaitlistMode ? 
            
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <form onSubmit={(e)=> e.preventDefault()}>
                  <Box>
                    {!waitlisted ?
                      <Box  sx={{minHeight: theme.spacing(6)}}>
                        {showButton && <TextField type='email' label="Email" variant="standard" required sx={{width: '270px'}}
                          error={!!waitlistEmailRequiredError}
                          autoFocus={true}
                          onChange={(e) => setWaitlistEmail(e.target.value)}
                          disabled={waitlisted || waitlistInProgress}
                        />}
                        <Typography color="error" variant="body2">{waitlistEmailRequiredError}</Typography>
                      </Box>
                      :
                    <Fade in={showButton} timeout={1000}>
                      <Box pl={7} pr={7}>
                        <Typography variant="body2" color="textPrimary" sx={{
                            fontWeight: 400,
                            maxWidth: '100%',
                            fontSize: {
                              xs: '0.8rem',
                              sm: '0.9rem',
                              md: '1rem',
                              lg: '1.1rem',
                            },
                          }}>
                          {waitlistStatusMessage}
                        </Typography>
                      </Box>
                    </Fade>
                    }
                  </Box>
                  <Box>

                    <Fade in={!waitlisted} timeout={1000}>
                      <Button
                        type='submit'
                        variant="contained"
                        size={isSmallScreen ? 'medium' : 'large'}
                        disabled={waitlisted || waitlistInProgress}
                        onClick={() => handleJoinToWaitlistClick()}
                        sx={btnSx}
                      >
                        Join Our Waitlist
                      </Button>
                    </Fade>

                    <Zoom in={waitlisted} timeout={2000}>
                      <Box sx={{height: '62px', marginTop: '-62px' }}>
                          <CheckCircleOutline style={{ color: 'green', width: '1.8em', height: '1.8em', marginTop: '-12px' }} />
                      </Box>
                    </Zoom>

                  </Box>
                </form>
              </Box> :
                <Button
                variant="contained"
                size={isSmallScreen ? 'medium' : 'large'}
                onClick={handleGetStartedClick} // Use handleGetStartedClick here
                sx={btnSx}
              >
                {buttonText}
              </Button>

            }
            </Fade>

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
