import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Slider,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from '@mui/material';
import { ExpandMore, Settings } from '@mui/icons-material';



const PREFIX = 'UserPreferencesModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

}));

const temperatureMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 0.5,
      label: '0.5',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 1.5,
      label: '1.5',
    },
    {
      value: 2,
      label: '2',
    },
];

const topPMarks = [
    { value: 0.2, label: '0.2' },
    { value: 0.4, label: '0.4' },
    { value: 0.6, label: '0.6' },
    { value: 0.8, label: '0.8' },
    { value: 1, label: '1' }
];

function frequencyText(value: any) {
    return `${value}`;
}

const frequencyMarks = [
    { value: 0, label: '0' },
    { value: 0.2, label: '0.2' },
    { value: 0.4, label: '0.4' },
    { value: 0.6, label: '0.6' },
    { value: 0.8, label: '0.8' },
    { value: 1., label: '1.0' }
]

const presencePenaltyMarks = [
    { value: 0.2, label: '0.2' },
    { value: 0.4, label: '0.4' },
    { value: 0.6, label: '0.6' },
    { value: 0.8, label: '0.8' },
    { value: 1, label: '1' }
]

export interface ILlmAdvancedSettings {
    temperature: number,
    topP: number,
    frequencyPenalty: number,
    presencePenalty: number,
    model: string
}


interface Props {
    firstOpen: boolean,
    initialSettings?: ILlmAdvancedSettings|null,
    onChange: (settings: ILlmAdvancedSettings) => void
}

const LlmAdvancedSettings: React.FC<Props> = ({ firstOpen, initialSettings, onChange }) => {

  const [settings, setSettings] = useState<ILlmAdvancedSettings>(initialSettings || { temperature: 0.5, topP: 1, frequencyPenalty: 0, presencePenalty: 0, model: 'smart' });

  useEffect(() => {
    onChange(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <Root
        className={classes.root}>
      <Accordion defaultExpanded={firstOpen}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel-content"
          id="panel-header"
        >
            Advanced configurations <Settings sx={{ml: 1, alignSelf: 'center' }}/>
        </AccordionSummary>
        <AccordionDetails>
            <Box pl={4} pb={2} sx={{maxWidth: '500px'}}>
                <Box pr={1}>
                    <Typography sx={{ flexShrink: 0 }}>Temperature (Controls randomness)</Typography>
                    <Slider
                        aria-label="Default"
                        defaultValue={0.5}
                        step={0.5}
                        min={0}
                        max={2}
                        marks={temperatureMarks}
                        value={settings.temperature}
                        onChange={(event, newValue) => {
                            setSettings({...settings, temperature: newValue as number});
                        }}
                    />
                </Box>

                <Box pr={1} pt={1}>
                    <Typography sx={{ flexShrink: 0 }}>Top P (Controls diversity)</Typography>
                    <Slider
                        aria-label="Default"
                        defaultValue={1}
                        step={null} // We need to set this to null so that we can specify our own steps.
                        min={0}
                        max={1.0}
                        marks={topPMarks}
                        value={settings.topP}
                        onChange={(event, newValue) => {
                        // We need to handle the case where the user selects a mark.
                        if (typeof newValue === 'number') {
                            setSettings({ ...settings, topP: newValue });
                        }
                        }}
                    />
                </Box>

                <Box pr={1} pt={1}>
                    <Typography sx={{ flexShrink: 0 }}>Frequency Penalty (Decreases the model's likelihood to repeat the same line verbatim)</Typography>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={20} // Default value is 0.2 (corresponds to value 20)
                        getAriaValueText={frequencyText}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={frequencyMarks}
                        min={0}
                        max={1} // Max is 1.0 (corresponds to value 100)
                        value={settings.frequencyPenalty}
                        onChange={(event, newValue) => {
                            setSettings({...settings, frequencyPenalty: newValue as number });
                        }}
                    />
                </Box>

                <Box pt={1}>
                    <Typography sx={{ flexShrink: 0 }}>Presence Penalty (Increases the model's likelihood to talk about new topics.)</Typography>
                    <Slider
                        aria-label="Default"
                        defaultValue={0}
                        step={0.2}
                        min={0}
                        max={1.0}
                        marks={presencePenaltyMarks}
                        value={settings.presencePenalty}
                        onChange={(event, newValue) => {
                        setSettings({...settings, presencePenalty: newValue as number });
                        }}
                    />
                </Box>

                <Box pt={1}>
                    <Typography sx={{ flexShrink: 0 }}>Model</Typography>
                    <ToggleButtonGroup
                        sx={{pt: 1}}
                        size="small"
                        value={settings.model}
                        exclusive
                        onChange={(event, newValue) => {
                        if (newValue !== null) {
                            setSettings({ ...settings, model: newValue });
                        }
                        }}
                    >
                        <ToggleButton value="simple" aria-label="Simple Model">
                        Simple
                        </ToggleButton>
                        <ToggleButton value="smart" aria-label="Smart Model">
                        Smart
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
}


export default LlmAdvancedSettings;