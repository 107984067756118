
import { Box, Tooltip, Typography } from '@mui/material'
import ImgThumbnailPlaceholder from '../Assets/resume-thumbnail-placeholder.svg'
import theme from '../theme'
import React from 'react'
import { Error } from '@mui/icons-material'


interface ResumeThumbnailProps {
    thumbnailUrl: string,
    onClick?: () => void,
    isResumeStatusFailed?: boolean,
}
  

const ResumeThumbnail: React.FC<ResumeThumbnailProps> = ({ thumbnailUrl, onClick, isResumeStatusFailed }) => {

    return (
      <Box pb={1} pt={1} sx={{ flexGrow: 1, position: 'relative' }}>

          { isResumeStatusFailed && (
          <Tooltip title="Resume has validation error, please edit it." placement="top">
            <Error sx={{ position: 'absolute', right: '26%', top: theme.spacing(1) }} color='error' />
          </Tooltip>)
          }

          <Box>
            {thumbnailUrl ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <img 
                  style={{
                    cursor: onClick ? 'pointer' : 'unset', 
                    border: `1px solid ${theme.palette.action.active}`, 
                    borderRadius: theme.spacing(1), 
                    display: 'block'
                  }}
                  src={"data:image/jpeg;base64," + thumbnailUrl}
                  onClick={onClick}
                  alt="pdf" 
                  width="50%" 
                  height="auto"
                />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <img 
                  style={{
                    cursor: onClick ? 'pointer' : 'unset',
                    border: `1px solid ${theme.palette.action.active}`, 
                    borderRadius: theme.spacing(1), 
                    display: 'block'
                  }}
                  src={ImgThumbnailPlaceholder}
                  onClick={onClick}
                  alt="pdf" 
                  width="50%" 
                  height="auto"
                />
              </Box>
            )}
          </Box>

      </Box>
    )
}


export default ResumeThumbnail;