import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import Button from './Button';

interface ButtonProps {
    loading?: boolean,
    disabled?: boolean,
    label: string,
    onClick: () => void
}

interface Props {
    open: boolean,
    onClose?: () => void,
    title: string,
    message: string,
    primaryButton: ButtonProps,
    secondaryButton?: ButtonProps,
    fullWidth?: boolean,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const ConfirmDialogBox: React.FC<Props> = ({ open, onClose, title, message, primaryButton, secondaryButton, fullWidth, maxWidth }) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    secondaryButton?.label &&
                    <Button
                        loading={secondaryButton.loading}
                        disabled={secondaryButton.disabled}
                        type={'secondary'}
                        label={secondaryButton.label}
                        onClick={secondaryButton.onClick}
                        autoWidth
                    />
                }
                <Button
                    loading={primaryButton.loading}
                    disabled={primaryButton.disabled}
                    type={'primary'}
                    label={primaryButton.label}
                    onClick={primaryButton.onClick}
                    autoWidth
                />
            </DialogActions>
        </Dialog>
    )
}


export default ConfirmDialogBox;