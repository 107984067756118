import {
    USER_SAVE,
    USER_REMOVE,
    USER_SAVE_CREDENTIALS,
    USER_ACTIVE_IMPERSONATION,
    USER_DEACTIVE_IMPERSONATION,
    USER_SIGN_OUT,
} from './Types';

import UserPool from '../../Configs/UserPool'
import { PATH_TO_ROOT } from '../../Router/Paths';
import { push } from '@lagunovsky/redux-react-router';
import { awsOnUserSignOut } from '../../Services/Aws/User';

export const onSignOutAction = (onComplete?: () => void): any => {
    return (dispatch: any) => {

        const currentUser = UserPool.getCurrentUser(); // Check if user is signed in

        if (!currentUser) {
            // If no current user is found, we're already signed out
            console.log('User is already signed out.');
            if (onComplete) {
                onComplete();
            }
            // Dispatch actions to clean up state and navigate
            dispatch(onUserRemoveAction());
            dispatch(push(PATH_TO_ROOT)); // Navigate to home or login
            return;
        }

        dispatch({ type: USER_SIGN_OUT });

        awsOnUserSignOut()
            .then(() => {
                dispatch(onUserRemoveAction());

                if (onComplete) {
                    onComplete();
                }

                dispatch(push(PATH_TO_ROOT)); // Use `push` to navigate to the home page
            })
            .catch((error: any) => {
                console.error('Error during sign out:', error); // Catch and log any errors
                if (onComplete) {
                    onComplete(); // Ensure onComplete is called even if there’s an error
                }
            });
    };
};

export const onUserSaveAction = (payload: { id: string, name: string, email: string, image: string, role: string, accessToken: string }): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_SAVE, payload: payload });
    }
}

export const onUserRemoveAction = (): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_REMOVE });
    }
}

export const onUserImpersonateActiveAction = (impersonatedId: string): any => {
    return (dispacth: any) => {
        dispacth({ type: USER_ACTIVE_IMPERSONATION, payload: impersonatedId });
    }
}

export const onUserImpersonateDeactiveAction = () => {
    return (dispacth: any): any => {
        dispacth({ type: USER_DEACTIVE_IMPERSONATION });
    }
}

export const onUserSaveCredentials = (payload: { provider: string, roleArn: string, region: string }): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_SAVE_CREDENTIALS, payload: payload });
    }
}




