// src/Components/Button.tsx

import React from 'react';
import { styled } from '@mui/material/styles';
import { Button as MuiButton, Theme, useTheme, SxProps } from '@mui/material';
import clsx from 'clsx';
import Loading from './Loading';
import { RefreshRounded } from '@mui/icons-material';

const PREFIX = 'Button';

const classes = {
  primary: `${PREFIX}-primary`,
  underline: `${PREFIX}-underline`,
  error: `${PREFIX}-error`,
  secondary: `${PREFIX}-secondary`,
  refresh: `${PREFIX}-refresh`,
  disabled: `${PREFIX}-disabled`,
};

const StyledMuiButton = styled(MuiButton)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.primary}`]: {
    backgroundColor: theme.palette.primary.main, 
    color: theme.palette.primary.contrastText,   
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, 
      boxShadow: theme.shadows[3],
      transform: 'translate(0,-7%)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },

  [`&.${classes.underline}`]: {
    ...theme.button.underline,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[3],
      // backgroundColor: theme.hover.button.backgroundColor,
      // color: theme.hover.button.color,
      transform: 'translate(0,-7%)',
    },
    '&:disabled': {
      color: theme.button.underline?.['&:disabled']?.color,
    }
  },


  [`&.${classes.error}`]: {
    ...theme.button.error,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[3],
      transform: 'translate(0,-7%)',
    },
  },

  [`&.${classes.secondary}`]: {
    // ...theme.button.secondary,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[3],
      // color: theme.button.secondary.color,
      transform: 'translate(0,-7%)',
    },
    '&:disabled': {
      // color: theme.button.secondary.disabled.color,
    }
  },

  [`&.${classes.refresh}`]: {
    ...theme.button.refresh,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[3],
      color: theme.button.refresh.color,
      transform: 'translate(0,-7%)',
    },
    '&:disabled': {
      // @ts-ignore
      color: theme.button.refresh?.['&:disabled']?.color,
    }
  },

  [`&.${classes.disabled}`]: {
    ...theme.button.disabled,
    '&:hover': {
      backgroundColor: theme.button.disabled.backgroundColor,
      color: theme.button.disabled.color,
    }
  }
}));

type ButtonType = "primary" | "secondary" | "disabled" | "refresh" | "error" | "underline" ;
type ButtonSize = "small" | "medium" | "large"

interface Props {
  label: string;
  onClick: (event: any) => void;
  type?: ButtonType;
  className?: any,
  autoWidth?: boolean,
  loading?: boolean,
  disabled?: boolean,
  size?: ButtonSize,
  endIcon?: React.ReactNode,
  startIcon?: React.ReactNode,
  sx?: SxProps<Theme>; 
}

const Button: React.FC<Props> = ({
  type,
  label,
  size,
  onClick,
  className,
  autoWidth,
  loading,
  disabled,
  endIcon,
  startIcon,
  sx, 
}) => {

  const theme = useTheme();

  if (type === "primary") {
    return (
      <StyledMuiButton
        disabled={disabled ? disabled : loading}
        disableElevation
        variant="contained"
        size={size}
        style={{ width: autoWidth ? 'auto' : theme.button.primary?.width }}
        className={clsx(classes.primary, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {loading ? <Loading type="circular" open={true} size={20} color={theme.colors.white} /> : label}
      </StyledMuiButton>
    );
  }
  else if (type === "secondary") {
    return (
      <StyledMuiButton
        disabled={disabled ? disabled : loading}
        style={{ width: autoWidth ? 'auto' : theme.button.disabled.width }}
        className={clsx(classes.secondary, className)}
        disableElevation
        variant="outlined"
        size={size}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {loading ? <Loading type="circular" open={true} size={20} color={theme.palette.action.disabledBackground} /> : label}
      </StyledMuiButton>
    );
  }
  else if (type === "disabled") {
    return (
      <StyledMuiButton
        disabled
        disableElevation
        variant="contained"
        size={size}
        style={{ width: autoWidth ? 'auto' : theme.button.disabled.width }}
        className={clsx(classes.secondary, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {label}
      </StyledMuiButton>
    );
  }
  else if (type === "error") {
    return (
      <StyledMuiButton
        disabled={disabled ? disabled : loading}
        disableElevation
        variant="outlined"
        size={size}
        style={{ width: autoWidth ? 'auto' : theme.button.disabled.width }}
        className={clsx(classes.error, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {label}
      </StyledMuiButton>
    );
  }
  else if (type === "refresh") {
    return (
      <StyledMuiButton
        variant="outlined"
        size={size}
        disabled={disabled ? disabled : loading}
        style={{ width: autoWidth ? 'auto' : theme.button.refresh.width }}
        className={clsx(classes.refresh, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        <RefreshRounded style={{ color: theme.colors.grey }} />
      </StyledMuiButton>
    );
  }
  else if (type === "underline") {
    return (
      <StyledMuiButton
        variant="outlined"
        size={size}
        disabled={disabled ? disabled : loading}
        style={{ width: autoWidth ? 'auto' : theme.button.primary.width }}
        className={clsx(classes.underline, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {label}
      </StyledMuiButton>
    );
  }
  else {
    return (
      <StyledMuiButton
        disabled={disabled ? disabled : loading}
        disableElevation
        variant="contained"
        size={size}
        style={{ width: autoWidth ? 'auto' : theme.button.primary.width }}
        className={clsx(classes.primary, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={sx} 
      >
        {loading ? <Loading type="circular" open={true} size={20} color={theme.colors.white} /> : label}

      </StyledMuiButton>
    );
  }
}

export default Button;
