import React from 'react';
import { styled } from '@mui/material/styles';
import { Snackbar, Box, useTheme, IconButton, Theme } from '@mui/material';
import {
    CheckCircleOutline as IconSuccess,
    ErrorOutline as IconError,
    ReportProblemOutlined as IconWarning,
    InfoOutlined as IconInfo,
    Close as IconClose,
} from '@mui/icons-material';
import PrimaryText from './PrimaryText';
import { useSelector, useDispatch } from 'react-redux';
import { onAlertCloseAction, selectAlert } from '../Redux';

const PREFIX = 'Alert';

const classes = {
    container: `${PREFIX}-container`,
    text: `${PREFIX}-text`
};

const StyledSnackbar = styled(Snackbar)(({ theme }: { theme: Theme }) => ({

    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0, 0, 0, 2),
        borderRadius: 5
    },

    [`& .${classes.text}`]: {
        color: theme.colors.white,
        marginLeft: theme.spacing(2)
    }
}));


const Alert: React.FC = () => {

    const { open, message, type, duration } = useSelector(selectAlert);
    const theme = useTheme();
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(onAlertCloseAction());
    }

    React.useEffect(() => {
        if (duration) {
            if (open) {
                setTimeout(() => {
                    onClose();
                }, duration);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);



    const renderAlert = () => {
        switch (type) {
            case 'success':
                return (
                    <Box style={{ backgroundColor: theme.colors.success }} className={classes.container} boxShadow={6}>
                        <IconSuccess style={{ color: theme.colors.white }} fontSize={'small'} />
                        <PrimaryText text={message} fontSize={'sm'} className={classes.text} />
                        <IconButton onClick={onClose} size="large">
                            <IconClose style={{ color: theme.colors.white }} fontSize={'small'} />
                        </IconButton>
                    </Box>
                );
            case 'error':
                return (
                    <Box style={{ backgroundColor: theme.colors.error }} className={classes.container} boxShadow={6}>
                        <IconError style={{ color: theme.colors.white }} fontSize={'small'} />
                        <PrimaryText text={message} fontSize={'sm'} className={classes.text} />
                        <IconButton onClick={onClose} size="large">
                            <IconClose style={{ color: theme.colors.white }} fontSize={'small'} />
                        </IconButton>
                    </Box>
                );
            case 'warning':
                return (
                    <Box style={{ backgroundColor: theme.colors.warning }} className={classes.container} boxShadow={6}>
                        <IconWarning style={{ color: theme.colors.white }} fontSize={'small'} />
                        <PrimaryText text={message} fontSize={'sm'} className={classes.text} />
                        <IconButton onClick={onClose} size="large">
                            <IconClose style={{ color: theme.colors.white }} fontSize={'small'} />
                        </IconButton>
                    </Box>
                );
            case 'info':
                return (
                    <Box style={{ backgroundColor: theme.colors.info }} className={classes.container} boxShadow={6}>
                        <IconInfo style={{ color: theme.colors.white }} fontSize={'small'} />
                        <PrimaryText text={message} fontSize={'sm'} className={classes.text} />
                        <IconButton onClick={onClose} size="large">
                            <IconClose style={{ color: theme.colors.white }} fontSize={'small'} />
                        </IconButton>
                    </Box>
                );
            default:
                return (
                    <Box style={{ backgroundColor: theme.colors.success }} className={classes.container} boxShadow={6}>
                        <IconSuccess style={{ color: theme.colors.white }} fontSize={'small'} />
                        <PrimaryText text={message} fontSize={'sm'} className={classes.text} />
                        <IconButton onClick={onClose} size="large">
                            <IconClose style={{ color: theme.colors.white }} fontSize={'small'} />
                        </IconButton>
                    </Box>
                );
        }
    }


    return (
        <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={open}
        //onClose={onClose}
        >
            {renderAlert()}
        </StyledSnackbar>
    );
}

export default Alert;