import {
    ApiInstance, ApiInstanceNoAuth,
} from "./Instances";

import {
    IUserPreferences,
} from "./interface";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;


const maxWaitTimeForPolling = 5000;

const UpInstance = ApiInstance;

export const httpOnGetUserBalance = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        UpInstance.get(`/users/user/balance`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

interface UserBalanceUpgradeMembershipPayload {
    userId: string,
    membershipType: string,
}

// TODO: change this method to use new requirements, e.g. buying 25 obexcoins then using them to upgrade membership
export const httpOnUserBalanceUpgradeMembership = (payload: UserBalanceUpgradeMembershipPayload) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': payload.userId
            }
        };
        const requestBody = {
        };
        UpInstance.post(`/users/user/balance/activate-paid-mode`, requestBody, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

interface UserBalanceCreateCheckoutSessionPayload {
    userId: string,
    obexcoinsBucketSize: string,
    allowToChargeOffSession: boolean,
    frontendReturnUrl: string,
    isUpgradeToProOnSuccess: boolean,
}


export const httpOnUserBalanceCreateCheckoutSession = (payload: UserBalanceCreateCheckoutSessionPayload) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': payload.userId
            }
        };
        const requestBody = {
            obexcoinsBucketSize: payload.obexcoinsBucketSize,
            allowToChargeOffSession: payload.allowToChargeOffSession,
            frontendReturnUrl: payload.frontendReturnUrl,
            isUpgradeToProOnSuccess: payload.isUpgradeToProOnSuccess,
        };
        UpInstance.post(`/users/user/balance/recharge`, requestBody, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnUserBalanceGetCheckoutSessionStatus = (userId: string, checkoutSessionId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                'checkout-session-id': checkoutSessionId
            }
        };
        UpInstance.get(`/users/user/balance/checkout-session-status`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

// data uploading api 


export const httpOnSaveFileData = (fileName: string, fileType: string, contentType: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            original_filename: fileName,
            mime_type: contentType,
            // force_invalid: true
        }

        ApiInstance.post('/resume_file', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumes = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                'include': [
                    'New',
                    'Processing',
                    'Ready',
                    'Failed',
                    'Edited',
                ].join(',')

            }
        };
        ApiInstance.get(`/resume/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResume = (resumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/resume/${resumeB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnEditResume = (resumeB62Id: string, resumeData: any, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.put(`/resume/${resumeB62Id}`, { 'resume': resumeData }, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumeThumbnail = (resumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/resume/${resumeB62Id}/thumb`, options)
            .then(res => {
                resolve(res?.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnSaveJobDescription = (jobDescription: string, jobTitleOnlyMode: boolean, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const key = jobTitleOnlyMode ? 'job_title_text' : 'job_description_text';
        const payload = {
            [key]: jobDescription
        }

        ApiInstance.post('/job', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollJobDescriptionExtracted = (jobB62Id: string, userId: string, wait: number = 3000) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollJobDescriptionExtracted wait:', wait);
    return new Promise((resolve, reject) => {
        httpOnGetJobDescription(jobB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    const message = "JobDescription processing Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        httpPollJobDescriptionExtracted(jobB62Id, userId, wait * 2).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollResumeExtracted = (resumeB62Id: string, userId: string, wait: number = 3000, updateCallback?: () => void) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollResumeExtracted wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetResume(resumeB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    reject(res)
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    updateCallback && updateCallback();
                    setTimeout(() => {
                        httpPollResumeExtracted(resumeB62Id, userId, wait * 2, updateCallback).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpPollGetResumeByResumeFileId = (resumeFileB62Id: string, userId: string, wait: number = 3000, max_try = 5) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollGetResumeByResumeFileId wait:', wait)
    return new Promise((resolve, reject) => {
        if (max_try === 0) {
            debugLogs && console.log('httpPollGetResumeByResumeFileId: Maximum number of tries reached')
            reject({ message: "Maximum number of tries reached" });
        }
        httpOnGetResumeByResumeFileId(resumeFileB62Id, userId)
            .then((res: any) => {
                resolve(res);
            })
            .catch(error => {
                debugLogs && console.log('httpPollGetResumeByResumeFileId: Error', error)
                if (error.status && error.status === 404) {
                    setTimeout(() => {
                        httpPollGetResumeByResumeFileId(resumeFileB62Id, userId, wait * 2, max_try - 1).then(resolve).catch(reject);
                    }, wait);
                } else {
                    reject(error.response);
                }
            });
    });
}

export const httpOnGetJobDescription = (jobB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/job/${jobB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetJobDescriptions = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/job/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetResumeByResumeFileId = (resumeFileB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/resume_by_file/${resumeFileB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnSubmitFeedback = (rating: number, feedback: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            feedback_rating: rating * 2, // backend expects rating * 2 to avoid decimals
            feedback_text: feedback,
        }

        ApiInstance.post(`/feedback`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnSubmitFeedbackLatex = (rating: number, feedback: string, userId: string,
    latexB62Id: string
) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            feedback_rating: rating * 2, // backend expects rating * 2 to avoid decimals
            feedback_text: feedback,
        }

        ApiInstance.post(`/feedback/${latexB62Id}`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnCreateTailored = (resumeB62Id: string, jobB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            resume_base62_id: resumeB62Id,
            job_base62_id: jobB62Id
        }

        ApiInstance.post('/tailored', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetTailored = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/${tailoredB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetTailoreds = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export interface iDetailedStatusContextWithCallback { // this is a way to count and know which sub step we are currently to show progress bar percentage update
    status: string;
    statusChangedCounter: number;
    detailedStatusCallback: (status: string, statusChangedCounter: number) => void;
}


export const httpPollTailoredExtracted = (tailoredB62Id: string, userId: string, wait: number = 3000, detailedStatusContext: iDetailedStatusContextWithCallback) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollTailoredExtracted wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetTailored(tailoredB62Id, userId)
            .then((res: any) => {
                if (detailedStatusContext) {

                    const prevStatus = detailedStatusContext?.status || '';
                    const newStatus = res?.data?.processing_status || prevStatus; // hold the prev status if new status came empty.
                    if (newStatus === '' && !prevStatus) {
                        detailedStatusContext.statusChangedCounter = 0;
                    } else if (prevStatus !== newStatus) {
                        detailedStatusContext.statusChangedCounter += 1;
                    }
                    detailedStatusContext.detailedStatusCallback(newStatus, detailedStatusContext.statusChangedCounter)
                    detailedStatusContext.status = newStatus;
                }

                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    const message = "Tailored cv generation Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        httpPollTailoredExtracted(tailoredB62Id, userId, wait * 2, detailedStatusContext).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnPutTailoredResume = (tailoredB62Id: string, tailoredResume: any, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            tailored_resume: tailoredResume['resume_details']
        }

        ApiInstance.put(`/tailored/${tailoredB62Id}`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnDeleteTailoredResume = (tailoredResumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        ApiInstance.delete(`/tailored/${tailoredResumeB62Id}`, options)
            .then(res => resolve(res.data))
            .catch(error => reject(error.response));
    });
};



export const httpOnCreateLatex = (tailoredB62Id: string, templateB62Id: string | null, preferences: IUserPreferences | null, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload: {
            tailored_resume_base62_id: string;
            template_base62_id?: string;
            override_preferences?: IUserPreferences | null;
        } = {
            tailored_resume_base62_id: tailoredB62Id,
            override_preferences: preferences
        };

        if (templateB62Id) {
            payload.template_base62_id = templateB62Id;
        }

        ApiInstance.post('/latex', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumeFolder = (resume_base62_id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                resume_base62_id: resume_base62_id
            }
        };

        ApiInstance.get('/resume_folder', options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetLatex = (latexB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/latex/${latexB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnCreatePdf = (latexB62Id: string, userId: string, renderInline: boolean = true) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            latex_resume_base62_id: latexB62Id,
            render_inline: renderInline
        }

        ApiInstance.post('/pdf', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnDeleteResume = (resumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.delete(`/resume/${resumeB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetTailoredComparisons = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/${tailoredB62Id}/comparisons`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollTailoredComparisons = (tailoredB62Id: string, userId: string, wait: number = 3000, maxTry: number=12) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollTailoredComparisons wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetTailoredComparisons(tailoredB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.comparison_status === "Failed") {
                    const message = "Tailored Comparisons processing Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.comparison_status)) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        httpPollTailoredComparisons(tailoredB62Id, userId, wait * 2, maxTry-1).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                console.log('httpPollTailoredComparisons Error:', error)
                if (error?.status === 404 && maxTry > 0) { // sometimes record is not created yet so wait it to be created.
                    setTimeout(() => {
                        httpPollTailoredComparisons(tailoredB62Id, userId, wait * 2, maxTry-1).then(resolve).catch(reject)
                    }, wait);
                } else {
                    reject(error.response);
                }
            });
    });
}

export const httpOnGetTailoredResumeThumbnail = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/${tailoredB62Id}/thumb`, options)
            .then(res => {
                resolve(res?.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetUser = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/user`, options)
            .then(res => {
                resolve(res?.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnPutUserPreferences = (userId: string, settings: IUserPreferences) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.put(`/user/settings`, settings, options)
            .then(res => {
                resolve(res?.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnPurgeAllMyData = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId,
            },
            params: {
                "purge": true
            }
        };
        ApiInstance.put(`/user/purge`, {}, options)
            .then(res => {
                resolve(res?.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnJoinWaitlist = (email: string) => {
    return new Promise((resolve, reject) => {
        const options = {
        };
        ApiInstanceNoAuth.post(`/waitlist`, {email}, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export interface IValidationError {
    message: string;
    path: string[];
}


export const parseFormValidationError = (error: any) => {
    let title = 'Sorry, something went wrong while saving edits.'
    let messages: any = []

    try {

        const e = error?.data;
        e.data = JSON.parse(e?.data);
    
        if (e?.statusCode === 400 && e?.message?.toLocaleLowerCase()?.includes('valid')) {// is validation error
            title = e?.message;
            messages = validationErrorsToMessages(e?.data);
        }
    
        return { title, 'messages': messages }

    } catch (err) {
        console.log('parseFormValidationError: ', err)
        return { title, 'messages': messages }
    }
  }

export function validationErrorsToMessages(errors: any[]) {
    let messages = []

    for (let i = 0; i < errors.length; i++) {

        let details = errors?.[i]?.loc; // example ['resume', 'experiance', 9, 'title', 'str']
        let path = []
        if (details?.length > 2) {
            // take details except first and last elements, first is allways 'resume', last is the data type
            path = details.slice(1, -1);

            // edge case when type is a tuple it looks like ['type...', 1], instead of type just ['type...']
            if (typeof details[details.length - 1] === "number" && path.length > 2) {
                path = path.slice(0, -1)
            }
        }
        
        const pathToDisplay = path.map((item: any) => { return typeof item === "number" ? 'item ' + (+item + 1) : item; }).join(' ');
        const message = `${errors?.[i]?.msg}: <span style="font-weight: bold">${pathToDisplay}</span>`;
        messages.push({message, path} as IValidationError);
    }

    return messages
}
  