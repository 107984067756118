// src/components/FAQSection.tsx

import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is the main benefit of using ResumeFactory.ai?",
    answer:
      "Recruiters say that tailoring your resume for each job will help you stand out in a crowd. Everyone would do it if it wasn’t so time-consuming. ResumeFactory.ai imports your existing resume and crafts professional, ATS-friendly, tailored resumes for specific job descriptions, saving you valuable time and effort. Put on extra polish for a special position, or streamline applying to many jobs.",
  },
  {
    question: "Is the resume generated optimized for Applicant Tracking Systems (ATS)?",
    answer:
      "Absolutely, that is our mission. We understand the importance of having a resume that can be easily parsed by applicant tracking systems (ATS) without risk of getting auto-rejected for the wrong reasons. For more background on what can go wrong, see <a href='https://www.linkedin.com/pulse/why-my-resume-getting-rejected-ats-pitch-n-hire-mh7qc/'>Why Is My Resume Getting Rejected by an ATS?</a>",
  },
  {
    question: "What is the workflow? Can I interactively review and edit changes?",
    answer:
      "After uploading your existing resume and providing a job description of interest, a tailored resume will be automatically crafted. A customized Summary statement and Skills section will be created or optimized, and education and experience are tuned for the role. You can interactively review and refine our suggested changes, ensuring a personalized document that truly represents your professional brand. Your tailored resume is then scored against an ideal one for the position, allowing further refinement. Preview and save your PDF when done.",
  },
  {
    question: "Can I customize my resume templates?",
    answer:
      "We provide one classic resume template – Jakes LaTeX – which is best tuned for ATS. In the future, we hope to provide more resume templates for you to choose from."
  },
  {
    question: "Is my data safe?",
    answer:
      "We take data security and privacy very seriously. We follow industry-standard security practices to ensure your personal information and resume data are protected. We will not sell or share your information with data brokers. You can purge your resume data from our platform at any time. We do utilize third-party services to enable some functionality on our platform – see our Terms of Service."
  }
];

export default function FAQSection() {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      textAlign="center"
      py={{ xs: 6, md: 10 }}
      sx={{
        background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`, // Subtle gradient with light gray tones
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
        Frequently Asked Questions
      </Typography>
      <Box maxWidth="md" mx="auto" textAlign="left">
        {faqData.map((item, index) => {
          const panelId = `panel${index}`;
          const isExpanded = expanded === panelId;
          return (
            <Accordion
              key={panelId}
              expanded={isExpanded}
              onChange={handleChange(panelId)}
              disableGutters
              square
              sx={{
                mb: 2,
                boxShadow: 'none',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:before': {
                  display: 'none',
                },
                '& .MuiAccordionSummary-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
                '& .MuiAccordionDetails-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
                sx={{
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-content': {
                    marginLeft: theme.spacing(1),
                  },
                  backgroundColor: isExpanded
                    ? theme.palette.action.hover
                    : 'inherit',
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  color="black"
                  sx={{ 
                    lineHeight: 1.6,
                    backgroundColor: 'white',
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{__html: item.answer}}
                >
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
}
