import React from 'react';
import { Typography, useTheme } from '@mui/material';

interface Props {
    text: string,
    fontSize?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl",
    className?: any
}

const PrimaryText: React.FC<Props> = ({ text, fontSize, className }) => {
    const theme = useTheme();
    return (
        <Typography
            style={{ fontSize: theme.fontSize[fontSize || "md"] }}
            className={className}>
            {text}
        </Typography>
    )
}


export default PrimaryText;