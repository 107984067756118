import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onSignOutAction } from '../Redux'; 

const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onSignOutAction()); 
    navigate('/'); 
  }, [dispatch, navigate]);

  return null; 
};

export default LogoutPage;
