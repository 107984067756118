import React, { useEffect, useState } from "react";
import {  styled } from '@mui/material';
import {  Box, Theme,} from "@mui/material";
import {DraggableArea} from 'react-draggable-tags';
import EditableChip from "./EditableChip";

const PREFIX = 'DraggableTagsList';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


interface DraggableTagsListProps {
  tags: string[]
  onChange?: (tags: string[]) => void

  skillCategoriesSkills?: string[]
  diffOriginSkills?: string[]
  showDiff: boolean
}


let idGenerator = 0;
const DraggableTagsListItems: React.FC<DraggableTagsListProps> = ({ tags, onChange, skillCategoriesSkills, diffOriginSkills, showDiff }) => {

  const [tagsWithIds, setTagsWithIds] = useState<any[]>([]);

  const diffOriginSkillsSet = new Set(diffOriginSkills);

  useEffect(() => {

      setTagsWithIds((tagsWithIds: any) => {
        const mappedTags = tags.map((tag: any) => {
            const tagWithId = {
              id: idGenerator++,
              content: <></>,
              tag: tag,
              existInDiffOrigin: diffOriginSkillsSet.has(tag),
            }
            return tagWithId;
        })

        return mappedTags;
    });


      setTagsWithIds((tagsWithIds: any) => {

        return tagsWithIds.map((tagWithId: any) => {

          tagWithId.content = <EditableChip label={tagWithId.tag}
            onLabelChange={(tag) => {handleTagEdited(tagWithId, tag, tagsWithIds)}} 
            variant="outlined"
            onDelete={() => handleTagDelete(tagWithId)}
            existInDiffOrigin={tagWithId.existInDiffOrigin}
            showDiff={showDiff}
          />;

          return tagWithId;
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleTagDelete = (tagWithId: any) => {
    setTagsWithIds((tagsWithIds: any) => {
      tagsWithIds = tagsWithIds.filter((el: any) => el.id !== tagWithId.id);
      onChange && onChange(tagsWithIds.map((el: any) => el.tag));
      return [...tagsWithIds];
    });
  }

  const handleTagEdited = (tagWithId: any, tag: string, tagsWithIds: any) => {

    if (tagWithId.tag === tag) {
      return;
    }
    tagWithId.tag = tag;

    const el = tagsWithIds.find((el: any) => el.id === tagWithId.id)
    el && (el.tag = tag);
    
    setTagsWithIds([...tagsWithIds]);

    onChange && onChange(tagsWithIds.map((el: any) => el.tag));
  }

  return <Root className={classes.root}>

    <DraggableArea
      tags={tagsWithIds}
      render={({tag, index}) => (tag.content)}
      onChange={function (tags) {
        setTagsWithIds([...tags]);
        return onChange && onChange(tags.map((el: any) => el.tag));
      }}
    />
   
  </Root>
}



export default DraggableTagsListItems;