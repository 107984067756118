import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Theme, styled, Chip  } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Undo,
} from '@mui/icons-material';

import DraggableTagsListItems from "./DraggableTagsList";
import EditableTypography from "./EditableTypography";
import { formDiffRedColor } from "./styles";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// "skill_categories": {
//   "anyOf": [
//       {
//           "additionalProperties": {
//               "type": "string"
//           },
//           "type": "object"
//       },
//       {
//           "type": "null"
//       }
//   ],
//   "default": null,
//   "description": "Candidate skills grouped by category",
//   "title": "Skill Categories"
// },

interface iSkillCategories {
  [key: string]: string[]
}

interface ResumeFormSkillCategoriesProps {
  control: any,
  skillCategories: iSkillCategories,
  onChangeSkillCategories: (skillCategories: iSkillCategories) => void;
  diffOriginData?: any;
  showDiff: boolean;
}


const ResumeFormSkillCategories: React.FC<ResumeFormSkillCategoriesProps> = ({ control, skillCategories, onChangeSkillCategories, diffOriginData, showDiff }) => {

  const [tags, setTags] = useState<iSkillCategories>(skillCategories);

  const diffOriginDataSkillCategories = diffOriginData?.['skill_categories']

  useEffect(() => {
    setTags(skillCategories);
  }, [skillCategories]);

  const handleTagsChanged = (newTags: any) => {
    setTags(newTags);
    onChangeSkillCategories(newTags);
  };

  const handleKeyRenamed = (newKey: string, oldKey: string, ) => {
    const newTags = { ...tags };
    delete newTags[oldKey];
    newTags[newKey] = tags[oldKey];
    handleTagsChanged(newTags);
  }

  const getRemovedSkillCategories = (): iSkillCategories => {
    if (!tags || !diffOriginDataSkillCategories) {
      return {} as iSkillCategories;
    }

    let removedSkillCategories: iSkillCategories = {};

    if (!diffOriginDataSkillCategories) {
      return {} as iSkillCategories;
    }
    for (let key in diffOriginDataSkillCategories) {
        if (!tags.hasOwnProperty(key)) {
          removedSkillCategories[key] = diffOriginDataSkillCategories[key];
        }
    }
    return removedSkillCategories;
  }

  const getRemovedSkills = (categoryName: string) => {

    if (!diffOriginDataSkillCategories || !diffOriginDataSkillCategories.hasOwnProperty(categoryName)) {
      return [];
    }
    
    const tagsSet = new Set(tags?.[categoryName]);
  
    const removedSkills = diffOriginDataSkillCategories[categoryName].filter((item: any) => !tagsSet.has(item));

    return removedSkills;
  }

  const onUndoDeleteSkill = (skill: string, categoryName: string) => {
    tags?.[categoryName].push(skill);
    handleTagsChanged(tags);
  }

  const onDeleteCategory = (categoryName: string) => {
    delete tags[categoryName];
    handleTagsChanged(tags);
  }

  const removedSkillCategories = getRemovedSkillCategories();

  return <Root className={classes.root}>
    <Typography>
      Skill Categories
      <IconButton onClick={() => {handleTagsChanged({...tags, '': []})}}
        disabled={skillCategories && '' in skillCategories}
        >
        <IconAdd/>
      </IconButton>
    </Typography>

    {
      Object.keys(skillCategories).map((key, index) => {
        return <>
        <EditableTypography label={key} onLabelChange={(newKey) => handleKeyRenamed(newKey, key)} onDelete={() => {onDeleteCategory(key)}} key={`${index}-${key}`}>

          <IconButton
            onClick={() => {onDeleteCategory(key)}}
            >
            <DeleteForever/>
          </IconButton>

          <IconButton
            onClick={() => {handleTagsChanged({...skillCategories, [key]: [...skillCategories[key], '']})}}
            disabled={ skillCategories?.[key].length > 0 && skillCategories[key][skillCategories[key].length -1] === '' }
            >
            <IconAdd/>
          </IconButton>
        </EditableTypography>

        <DraggableTagsListItems
          tags={tags[key] || []}
          onChange={(skills) => {handleTagsChanged({...tags, [key]: skills})}}
          skillCategoriesSkills={skillCategories?.[key]}
          diffOriginSkills={diffOriginDataSkillCategories?.[key]}
          showDiff={showDiff}
        />
        { showDiff &&
          <Box sx={{display: 'flex'}} pb={1} pl={1}>
            {getRemovedSkills(key).map((skill: string) => {
              return <Chip
                key={`${index}-${skill}`}
                label={skill}
                onDelete={() => onUndoDeleteSkill(skill, key)}
                variant="outlined"
                sx={{
                  m:'1px', maxWidth: '70vw',
                }}
                style={{ backgroundColor: formDiffRedColor }}
                deleteIcon={<Undo />}
              />
            })}
          </Box>
        }
        </>
      })

    }

    {
      Object.keys(removedSkillCategories).map((key, index) => {
        return <>
        <EditableTypography label={key}
          onLabelChange={(newKey) => handleKeyRenamed(newKey, key)}
          onDelete={() => {}}
          showAsDeleted={true}
          onUndoDelete={() => {handleTagsChanged({...tags, [key]: removedSkillCategories[key]})}}
          >

          <IconButton>
            <Undo/>
          </IconButton>

        </EditableTypography>

        <Box sx={{display: 'flex', flexWrap: 'wrap' }}  p={1}>
          {removedSkillCategories[key].map((skill: string) => {
            return <Chip
              key={`${index}-${skill}`}
              label={skill}
              variant="outlined"
              sx={{ m:'1px', maxWidth: '70vw' }}
              style={{ backgroundColor: formDiffRedColor }}
            />
          })}
        </Box>
        </>
      })

    }
  
  </Root>
}



export default ResumeFormSkillCategories;