import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress, Rating, TextField, Theme, Typography } from '@mui/material';
import useUser from "../Hooks/useUser";
import { httpOnSubmitFeedback, httpOnSubmitFeedbackLatex } from "../Services/Http";
import theme from "../theme";

import { useLocation } from 'react-router-dom';

const PREFIX = 'Progressbar';

const classes = {
    root: `${PREFIX}-root`,
    barStyle: `${PREFIX}-barStyle`
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%'
    },
    [`& .${classes.barStyle}`]: {
        
    },
}));

interface Props {
    latexB62Id?: string,
    onSubmitCompleted: () => void
}

const FeedbackForm: React.FC<Props> = ({ latexB62Id, onSubmitCompleted }) => {

    const [feedback, setFeedback] = useState('');
    const [rating, setRating] = useState<number|null>(null);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { userId } = useUser();
    const [message, setMessage] = useState<string>('');

    const location = useLocation();
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        setInProgress(true);

        console.log(location, location.pathname)

        let promise = null;
        if (latexB62Id) {
            promise = httpOnSubmitFeedbackLatex(rating || 0, feedback, userId, latexB62Id)
        } else {
            promise = httpOnSubmitFeedback(rating || 0, feedback + '|' + location.pathname, userId)
        }

        promise
        .then((res: any) => {
            setInProgress(false);
            setMessage('Thank you for your feedback!')
            onSubmitCompleted();
        })
        .catch((err: any) => {
            setInProgress(false);
            setMessage('Sorry, something went wrong.')
            onSubmitCompleted();
        }).finally(() => {
        });

    }

    const isInvalid = () => {
        return feedback.length === 0 || rating === null;
    }

    return <Root className={classes.root}>

        <Box p={1} component="form" sx={{ '& .MuiTextField-root': { minWidth: '25ch', width: '100%' } }} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography>
               {/* What worked well and what could be improved? */}
               {/* Please give us your feedback! */}
               { latexB62Id ? 'For this resume what changes do you like and what could be improved?' : 'What worked well and what could be improved?' }
            </Typography>
            <Box pt={1}>
                <Box>
                    <TextField id="outlined-multiline-static" label="Feedback" multiline rows={4} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                </Box>

                <Box pt={1} pb={2}  sx={{justifyContent: 'right', display: 'flex'}}>
                    <Rating name="simple-controlled" value={rating} precision={0.5} onChange={(event, newValue) => {setRating(newValue)}}/>
                </Box>
            
            </Box>
            <Box sx={{justifyContent: 'right', display: 'flex'}}>
                { inProgress && <CircularProgress sx={{
                    marginRight: theme.spacing(1),
                    marginTop: '8px',
                    width: '20px !important', height: '20px !important'}}/>
                }
                <Button variant="contained" color="primary" type="submit" disabled={inProgress || isInvalid()}>Submit</Button>
            </Box>
            <Typography pt={1}>{message}</Typography>
        </Box>
        
    </Root>

}


export default FeedbackForm;