import { Box, Button, CircularProgress, IconButton, LinearProgress, Skeleton, Tooltip, Typography, styled, Drawer, ButtonGroup} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DeleteForever, Download, Edit, EditNote, Folder, OpenInFull, Speed, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { httpOnCreatePdf, httpOnGetJobDescription, httpOnDeleteResume, httpOnGetResume } from "../Services/Http";
import useUser from "../Hooks/useUser";
import theme from "../theme";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
//import { Comparison } from "../Services/Http/interface";
import ComparisonScores from "../Components/ComparisonScores";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;


const drawerWidth = 400;
const drawerSpaceDividerWidth = 16;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      },
    },
  ],
}));

// src/pages/PdfResumeViewer.js
export default function PdfResumeViewer() {

  const [fileName, setFileName] = React.useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = React.useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [jobCompany, setJobCompany] = useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingDownload, setIsLoadingDownload] = React.useState<boolean>(false);
  const [generateInProgress, setGenerateInProgress] = React.useState<boolean>(false);
  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);
  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = useState(false);



  const navigate = useNavigate();
  const params = useParams();
  const { userId } = useUser();

  const dispatch = useDispatch();

  const [pdfData, setPdfData] = React.useState<any>(null);
  // eslint-disable-next-line 
  const [resumeB62Id, setResumeB62Id] = React.useState<string>("");

  React.useEffect(() => {

    setTimeout(() => {
      setDrawerOpen(true)
    }, 1000)

    async function fetchData() {
      setIsLoading(true);

      let pdfData = null;
      try {
        debugLogs && console.log('httpOnCreatePdf')
        const res: any = await httpOnCreatePdf(params.latexB62Id!, userId)
        pdfData = res.data;
        setPdfData(pdfData);

        setTimeout(() => {
          setIsLoading(false);

          setTimeout(() => {
            setIsEditButtonTooltipOpen(true);
          }, 1000)
        }, 1000) // let pdf iframe to be rendered

      } catch (e) {
        displayError('Sorry, something went wrong while generating the final pdf resume.')
        setIsLoading(false);
        return;
      }

      setErrorMessage('');
    }

    fetchData();

    httpOnGetResume(params.resumeB62Id!, userId).then((res: any) => {
      const resume = res.data;
      setFileName(resume.file_name);
      setResumeUpdatedAt(formatUpdatedAt(resume.updated_at));
    }).catch((e: any) => {})

    httpOnGetJobDescription(params.jobB62Id!, userId).then((res: any) => {
      const jb = res.data;
      let title = jb?.analysis?.job_title?.title || jb?.job_title_details?.title || jb?.job_details?.titles?.[0];
      setJobTitle(title);
      setJobCompany(jb?.job_details?.company || jb?.job_title_details?.industry);
    }).catch((e: any) => {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.latexB62Id]);

  const displayError = (errorMessage: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
    setGenerateInProgress(false);
  }

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`)
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/edit`);

  const editTailoredResume = ()  =>  navigate(`/app/${params.resumeB62Id}/job/${params.jobB62Id}/tailored-resume/${params.tailoredB62Id}`);

  const downloadResume = async () => {
    setIsLoadingDownload(true);

    let pdfData = null;
    try {
      debugLogs && console.log('httpOnCreatePdf')
      const res: any = await httpOnCreatePdf(params.latexB62Id!, userId, false)
      pdfData = res.data;

      try {
        downloadResumeFromPresignedUrl(pdfData);
      

        setIsLoadingDownload(false);

        setTimeout(() => { feedbackPopupRef?.current?.open(); }, 1000);
      }
      catch (e) {
        console.log('download button error:', e)
      }

    } catch (e) {
      displayError('Sorry, something went wrong while downloading the final pdf resume.')
      setIsLoadingDownload(false);
      return;
    }

    setErrorMessage('');

  }

  const downloadResumeFromPresignedUrl = (pdfData: any) => {
    const url = pdfData['presigned_url'];
    const fileName = pdfData['pdf_key'];
    const link = document.createElement('a');
    link.href = url;
    // link.setAttribute("target", "_blank");
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);

  }

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const [isScoresDialogIsOpen, setIsScoresDialogIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/folder`);


  const feedbackPopupRef = useRef<{open: () => void }>(null);

  const [isEditButtonTooltipOpen, setIsEditButtonTooltipOpen] = useState(false);


  return (
    <Box pt={3}>

      <Box sx={{'display': 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }} pb={3}>

        <Box>
          <Box sx={{'display': 'flex', flexWrap: 'wrap' }}>
            <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
            <Box sx={{'display': 'flex'}}>
              <IconButton color={'error'} onClick={() => setRemoveResumeDialogOpen(true)} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
            </Box>
            { removeResumeInProgress && <CircularProgress sx={{
              marginTop: '10px',
              width: '20px !important', height: '20px !important'}}/>
            }
            <Box sx={{'display': 'flex'}}>
              {/* <Button variant="outlined" endIcon={<Edit/>}
                onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !resumeB62Id}>
                Edit original resume text.
              </Button> */}
              <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                <span>
                <IconButton color={'primary'}
                  onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                  <EditNote/>
                </IconButton>
                </span>
              </Tooltip>
            </Box>

            <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
              <span>
                <IconButton color={'primary'}
                    onClick={() => {navigateToResourcesView(params.resumeB62Id!)}} disabled={generateInProgress || removeResumeInProgress}><Folder/></IconButton>
              </span>
            </Tooltip>
          </Box>

          <Box sx={{'display': 'flex', flexWrap: 'wrap'}}>
            <Box sx={{'display': 'flex', 'align-items': 'center', flexWrap: 'wrap'}}>
              <Typography>{jobTitle}</Typography>
              {!jobTitle && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
              <Typography> &nbsp;&#x2022;&nbsp; </Typography>
              {!jobCompany && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
              <Typography color="grey">{jobCompany}</Typography>
            </Box>
            {/* <Box sx={{'display': 'flex'}}>
              <IconButton color={'error'} onClick={() => removeResume(params.resumeB62Id!)} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
            </Box>
            { removeResumeInProgress && <CircularProgress sx={{
              marginTop: '10px',
              width: '20px !important', height: '20px !important'}}/>
            }
            <Box sx={{'display': 'flex'}}>
              <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                <span>
                <IconButton color={'primary'}
                  onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                  <EditNote/>
                </IconButton>
                </span>
              </Tooltip>
            </Box> */}
          </Box>
        </Box>

        <Box sx={{'display': 'flex', flexWrap: 'wrap'}}>

          <Box pt={2} mr={2}>
            <Tooltip
              title={
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                     width: '240px' 
                     }}>
                    <InfoIcon style={{ marginRight: '8px' }} />
                    <span>
                      Edit or view changes proposed by ResumeFactory
                    </span>
                    <IconButton onClick={() => setIsEditButtonTooltipOpen(false)} size="small" sx={{position: 'absolute', right: 0, color: 'white'}}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </>
              }
              open={isEditButtonTooltipOpen}
              onClose={() => setIsEditButtonTooltipOpen(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow={true}
              placement="top"
            >
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<Edit />}
                disabled={isLoading}
                onClick={() => editTailoredResume()}
              >
                Edit
              </Button>
            </Tooltip>
          </Box>

          <Box pt={2}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<Download />}
              disabled={isLoading || isLoadingDownload}
              onClick={() => downloadResume()}
            >
              Download PDF
            </Button>
          </Box>

          <Box pt={2} ml={2} sx={{'display': {xs: 'none', sm: 'none', md: 'flex'}}}>

            <ButtonGroup variant="contained">
              <Button
                component="label"
                role={undefined}
                // variant="outlined"
                tabIndex={-1}
                startIcon={ isDrawerOpen ? <VisibilityOff /> : <Speed /> }
                disabled={isLoading}
                onClick={() => toggleDrawer()}
              >
                Relevancy score
              </Button>
              <Button
                // variant="outlined"
                tabIndex={-1}
                endIcon={ <OpenInFull/> }
                disabled={isLoading}
                onClick={() => setIsScoresDialogIsOpen(true)}
              >

              </Button>
              
            </ButtonGroup>
          </Box>


        </Box>

      </Box>

      { isLoading && <Box>
        <LinearProgress sx={{marginTop: '-4px'}}/>
      </Box>}

      <Box pb={3}>

        <Box sx={{ display: 'flex', height: {xs: 'unset', md: '100vh'}, flexDirection: { xs: 'column-reverse', md: 'unset'} }} >

          <Main open={isDrawerOpen} sx={{ display: 'flex', height: {xs: '100vh', md: '100%'}, marginTop: {xs: 1, sm: 1, md: 0 } }} >

              <Box sx={{flexGrow: 1, 'width': '100%'}}>
              {pdfData && (
                          <iframe
                            title="PDF Document Viewer" 
                            style={{ width: '100%', height: '100%', border: 'none' }}
                            src={pdfData['presigned_url']}
                          />
                        )}

                <Box sx={{flexGrow: 1, 'width': '100%', display: isLoading ? 'block' : 'none' }}>

                  <Box pt={1} pb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={40} />
                  </Box>
                  <Box pt={1} pb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={400} />
                  </Box>
                </Box>

                { errorMessage &&
                  <Box pb={2} pt={1}>
                    <Typography color={'error'}>{errorMessage}</Typography>
                  </Box> 
                }
                
              </Box>

          </Main>

          <Drawer
              sx={{
                width: { drawerWidth },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: { sm: 'unset', md: drawerWidth - drawerSpaceDividerWidth  },
                  position: 'relative',
                  marginLeft: { sm: 0, md: `${drawerSpaceDividerWidth}px` },
                  [theme.breakpoints.down('md')]: {
                    visibility: 'visible !important',
                    transform: 'none !important',
                  }
                },
              }}
              variant="persistent"
              anchor="right"
              open={isDrawerOpen}
          >

            <Box>
              <ComparisonScores tailoredB62Id={params.tailoredB62Id!} onClose={() => {setDrawerOpen(false)}}
                isDialogOpen={isScoresDialogIsOpen} onChangeDialogOpen={(isOpen) => {setIsScoresDialogIsOpen(isOpen)}}
              ></ComparisonScores>
            </Box>
          </Drawer>

        </Box>

      </Box>

      <FeedbackSidePopup latexB62Id={params.latexB62Id!} ref={feedbackPopupRef}></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this resume!"
        open={removeResumeDialogOpen}
        primaryButton={{ label: 'Delete', onClick: () => {removeResume(params.resumeB62Id!);setRemoveResumeDialogOpen(false);} }}
        secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
        fullWidth
        onClose={() => setRemoveResumeDialogOpen(false)}
      />

    </Box>
  );
}
