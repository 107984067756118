import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Fab, IconButton, Paper, Popper, Typography, Badge } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackForm from './FeedbackForm';
import theme from '../theme';

interface FeedbackPopupProps {
  latexB62Id?: string,
  ref?: any
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = forwardRef(({ latexB62Id }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setBadgeVisible(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'feedback-popper' : undefined;

  const [badgeVisible, setBadgeVisible] = React.useState(false);

  useEffect(() => {
    if (!latexB62Id) {
      return
    }
    setTimeout(() => {
        if (!anchorEl) {
            setBadgeVisible(true);
        }
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fabBtnRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    open: () => {
      if (!open) {
        fabBtnRef?.current?.click();
      }
    }
  }));


  return (
    <Box sx={{
      position: 'fixed',
      right: '-35px',
      bottom: '57px',
      transform: 'translateY(-50%)', zIndex: 100000 }}>
      {/* <Badge color="error"
         overlap="circular"
         variant="dot"
        invisible={!badgeVisible}> */}
        <Fab
            aria-describedby={id}
            onClick={handleClick}
            color="primary"
            size="medium"
            ref={fabBtnRef}
            variant="extended"

            sx={{
              zIndex: 0,
              backgroundColor: '#007bff',
              color: 'white',
              borderRadius: '4px', // Change this value to achieve the desired shape (circle or square)
              rotate: '270deg'

            }}
        >
            Feedback
        </Fab>
      {/* </Badge> */}
      <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" disablePortal sx={{pb: 1}}>
        <Paper elevation={3} sx={{ p: 2, maxWidth: 300 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6">
                { latexB62Id ? 'How did we do?' : 'Please give us your feedback!' }
                {/* Please give us your feedback! */}
            </Typography>
            <IconButton size="small" onClick={() => setAnchorEl(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography>
            <FeedbackForm latexB62Id={latexB62Id} onSubmitCompleted={() => setTimeout(() => setAnchorEl(null), 1500)} />
          </Typography>
        </Paper>
      </Popper>
    </Box>
  );
});

export default FeedbackPopup;