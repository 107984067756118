import axios from 'axios';
import { setAuthHeader } from '../Aws/Session';

export const ApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

export const ApiInstanceNoAuth = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URL
});


ApiInstance.interceptors.request.use(setAuthHeader);

