// Add type definition for window object
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

// Safe gtag function that won't throw if blocked
const safeGtag = (...args: any[]) => {
  try {
    if (window.gtag) {
      window.gtag(...args);
    }
  } catch (error) {
    console.debug('Analytics blocked or failed:', error);
  }
};

// Initialize gtag function if it doesn't exist
if (typeof window.gtag !== 'function') {
  window.gtag = function() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  };
}

// Utility functions for tracking
export const trackPageView = (path: string) => {
  safeGtag('config', GA_MEASUREMENT_ID!, {
    page_path: path,
    anonymize_ip: true,
    forceSSL: true
  });
};

export const trackEvent = (action: string, category: string, label: string, value?: number) => {
  safeGtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
}; 